// React
import { FunctionComponent, JSXElementConstructor, ReactElement, useCallback, useEffect, useState } from "react";
// Components
import { Page, PageConfiguration } from "@fyrstain/fhir-front-library";
import { MenuItem } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// Authentication
import UserService from "src/services/UserService";
// React router dom
import { useNavigate } from "react-router-dom";
// Fhir
import Client from "fhir-kit-client";
import { Bundle, Device } from "fhir/r5";

const PandoraPage: FunctionComponent<{
    titleKey?: string;
    loading?: boolean;
    children?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
    needsLogin: boolean;
}> = (props) => {

    /////////////////////////////////
    //        NAVIGATION           //
    /////////////////////////////////

    const [validatorMenus, setValidatorMenus] = useState([] as MenuItem[]);

    const navigate = useNavigate();

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    const handleLogin = useCallback((config: MenuItem) => {
        if (config.needsLogin) {
            if (!UserService.isAuthenticated()) {
                UserService.doLogin();
            }
        }
    }, [navigate]);

    useEffect(() => {
        getValidatorsMenus();
    }, []);

    async function getValidatorsMenus() {
        try {
            const response = await fhirClient.search({
                resourceType: "Device",
                searchParams: { "type": "http://fyrstain.com/pdt/ValueSet/systemTypes|validator" }
            });
            if (response.resourceType !== 'Bundle') {
                throw Error(response.statusText);
            }
            const bundle: Bundle = response as Bundle;
            setValidatorMenus(bundle.entry?.filter(entry => entry.resource?.resourceType === "Device")
                .map(entry => entry.resource)
                .map(resource => {
                    return {
                        title: (resource as Device)?.displayName,
                        link: '/ValidatorHome/' + resource?.id,
                        needsLogin: false
                    } as MenuItem
                }) ?? []);
        } catch (error) {
            console.log(error);
        }
    }

    const handleLangChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value);
    };

    const fullConfig: PageConfiguration = {
        // Translation
        language: i18n.t,
        navigationBarConfigs: {
            homeLink: '/Home',
            logoLink: '/assets/pandoraLogoHoriz.svg',
            alt: 'Pandora Logo',
            authentication: {
                token: UserService.getKC().token,
                handleLogin: handleLogin,
                doLogin: UserService.doLogin,
                doLogout: UserService.doLogout,
                isAuthenticated: () => UserService.isAuthenticated(),
                getUserName: () => UserService.getUsername(),
            },
            menuItems: [{
                title: i18n.t('navbar.items.validators'),
                subItems: validatorMenus
            },
            {
                title: i18n.t('navbar.items.tests'),
                subItems: [
                    {
                        title: i18n.t('navbar.items.testscripts'),
                        link: '/TestScript',
                        needsLogin: true
                    },
                    {
                        title: i18n.t('navbar.items.testplans'),
                        link: '/TestPlans',
                        needsLogin: true
                    },
                    // {
                    //     title: i18n.t('navbar.items.myplans'),
                    //     link: '/MyTestPlans',
                    //     needsLogin: true
                    // },
                ]
            },
            {
                title: i18n.t('navbar.items.reports'),
                subItems: [
                    {
                        title: i18n.t('navbar.items.testscripts'),
                        link: '/TestReports',
                        needsLogin: true
                    },
                    {
                        title: i18n.t('navbar.items.testplans'),
                        link: '/TestPlan/Report',
                        needsLogin: true
                    },
                    {
                        title: i18n.t('navbar.items.validation'),
                        link: '/ValidationReports',
                        needsLogin: true
                    },
                    {
                        title: i18n.t('navbar.items.evaluation'),
                        link: '/EvaluationReports',
                        needsLogin: true
                    },
                ]
            },
            {
                title: i18n.t('navbar.items.messages'),
                link: '/Exchanges',
                needsLogin: true
            },
            {
                title: i18n.t('navbar.items.systems'),
                link: '/Systems',
                needsLogin: true
            }
            ],
            dropDownItems: [],
        },
        titleKey: props.titleKey,
        loading: props.loading,
        children: props.children,
        needsLogin: props.needsLogin,
        footerConfigs: {
            languages: {
                default: i18n.language,
                onChange: handleLangChange,
                options: [
                    {
                        label: "English",
                        value: "en"
                    },
                    {
                        label: "Français",
                        value: "fr"
                    }
                ]
            },
            logo: [
                {
                    src: "https://fyrstain.com/wp-content/uploads/2022/10/Logo_fyrstain_horyzontal.svg",
                    alt: "Horizontal logo type",
                    href: "/Home"
                },
                ...(process.env.REACT_APP_DISPLAY_CLIENT_LOGO === 'true' ?
                    [
                        {
                            src: "https://fyrstain.com/wp-content/uploads/2022/10/Logo_fyrstain_horyzontal.svg",
                            alt: "Horizontal logo type",
                            href: "/Home"
                        },
                        {
                            src: "/assets/client_logo.jpg",
                            alt: "HL7 Europe logo"
                        }
                    ]
                    : [])
            ],
            items: [
                {
                    label: i18n.t('footer.items.about'),
                    href: "/"
                },
                {
                    label: i18n.t('footer.items.contact'),
                    href: "/"
                },
                {
                    label: i18n.t('footer.items.problemtracking'),
                    href: "/"
                }
            ]
        }
    };

    return <Page {...fullConfig} />;
};

export default PandoraPage;