// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestOperationSection_titleContainer__hGYIN {
    margin-left: 1rem;
}

.TestOperationSection_accordionHeaderContainer__ANsph {
    width: 100%;
}

.TestOperationSection_infoMessage__aU7fp {
    margin-left: 1rem;
}

.TestOperationSection_noOperationCard__\\+ddT2 {
    background-color: white !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 5.2rem;
}

.TestOperationSection_smallColumnsWidthOperation__6XMjq {
    width: 15%;
}

.TestOperationSection_columnsWidthOperation__STGKQ {
    width: 22%;
}

.TestOperationSection_largeColumnsWidthOperation__RhdL0 {
    width: 43%;
}

.TestOperationSection_smallColumnsWidthAssert__TCm1d {
    width: 15.2%;
}

.TestOperationSection_columnsWidthAssert__er3zj {
    width: 21.6%;
}

.TestOperationSection_largeColumnsWidthAssert__a3bTS {
    width: 43.6%;
}

.TestOperationSection_chevron__I3Yw5 {
    cursor: pointer;
}

.TestOperationSection_largeTable__8JlRe {
    width: 90%;
}

.TestOperationSection_operationRow__78n2t {
    border: var(--color-lightGray) solid 0.1rem;
}

.TestOperationSection_addOperationIcon__yzi0d {
    margin-left: 1rem;
}

.TestOperationSection_testSection__LxtNe {
    margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/TestReport/TestOperationSection/TestOperationSection.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".titleContainer {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.accordionHeaderContainer {\r\n    width: 100%;\r\n}\r\n\r\n.infoMessage {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.noOperationCard {\r\n    background-color: white !important;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n    height: 5.2rem;\r\n}\r\n\r\n.smallColumnsWidthOperation {\r\n    width: 15%;\r\n}\r\n\r\n.columnsWidthOperation {\r\n    width: 22%;\r\n}\r\n\r\n.largeColumnsWidthOperation {\r\n    width: 43%;\r\n}\r\n\r\n.smallColumnsWidthAssert {\r\n    width: 15.2%;\r\n}\r\n\r\n.columnsWidthAssert {\r\n    width: 21.6%;\r\n}\r\n\r\n.largeColumnsWidthAssert {\r\n    width: 43.6%;\r\n}\r\n\r\n.chevron {\r\n    cursor: pointer;\r\n}\r\n\r\n.largeTable {\r\n    width: 90%;\r\n}\r\n\r\n.operationRow {\r\n    border: var(--color-lightGray) solid 0.1rem;\r\n}\r\n\r\n.addOperationIcon {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.testSection {\r\n    margin-bottom: 0.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `TestOperationSection_titleContainer__hGYIN`,
	"accordionHeaderContainer": `TestOperationSection_accordionHeaderContainer__ANsph`,
	"infoMessage": `TestOperationSection_infoMessage__aU7fp`,
	"noOperationCard": `TestOperationSection_noOperationCard__+ddT2`,
	"smallColumnsWidthOperation": `TestOperationSection_smallColumnsWidthOperation__6XMjq`,
	"columnsWidthOperation": `TestOperationSection_columnsWidthOperation__STGKQ`,
	"largeColumnsWidthOperation": `TestOperationSection_largeColumnsWidthOperation__RhdL0`,
	"smallColumnsWidthAssert": `TestOperationSection_smallColumnsWidthAssert__TCm1d`,
	"columnsWidthAssert": `TestOperationSection_columnsWidthAssert__er3zj`,
	"largeColumnsWidthAssert": `TestOperationSection_largeColumnsWidthAssert__a3bTS`,
	"chevron": `TestOperationSection_chevron__I3Yw5`,
	"largeTable": `TestOperationSection_largeTable__8JlRe`,
	"operationRow": `TestOperationSection_operationRow__78n2t`,
	"addOperationIcon": `TestOperationSection_addOperationIcon__yzi0d`,
	"testSection": `TestOperationSection_testSection__LxtNe`
};
export default ___CSS_LOADER_EXPORT___;
