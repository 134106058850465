// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValidationReport_issues__dhVA8 {
  margin-top: 3rem;
}

.ValidationReport_formTextLabel__kRDiZ {
  margin: 0 1rem 0 1.5rem;
  text-align: justify;
  line-height: 1.2rem;
}

.ValidationReport_summaryStatusContainer__LLtzk {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 2rem 0;
}

.ValidationReport_summaryStatusLabel__lYsHT {
  width: 10%;
  margin-right: 3rem;
}

.ValidationReport_progressBarContainer__smqbr {
  width: 78%;
  margin-left: 1rem;
}

.ValidationReport_alert__bMcDU {
  width: 97%;
  margin: auto;
}

.ValidationReport_form__4B9t\\+ {
  margin: 2rem 0 0 0;
}

.ValidationReport_alertIcon__qhmbG {
  margin: 0 1rem 0 0;
}

.ValidationReport_alertTitle__vlEUr {
  margin-left: 1rem;
}

.ValidationReport_label__DA-9U {
  margin-right: 0.5rem;
}

.ValidationReport_successText__2YRAM {
  margin-top: 1rem;
}

.ValidationReport_notificationContent__v6Udk {
  display: flex;
  justify-content: space-between;
}

.ValidationReport_labelsNotification__GVR8Z {
  margin-top: 1rem;
}

@media screen and (max-width: 1200px) {

  .ValidationReport_progressBarContainer__smqbr {
    width: 73%;
  }
}

@media screen and (max-width: 991px) {

  .ValidationReport_progressBarContainer__smqbr {
    width: 65%;
  }

  .ValidationReport_notificationContent__v6Udk {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/validationReport/ValidationReport.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE;IACE,UAAU;EACZ;AACF;;AAEA;;EAEE;IACE,UAAU;EACZ;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".issues {\r\n  margin-top: 3rem;\r\n}\r\n\r\n.formTextLabel {\r\n  margin: 0 1rem 0 1.5rem;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.summaryStatusContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin: 2rem 0 2rem 0;\r\n}\r\n\r\n.summaryStatusLabel {\r\n  width: 10%;\r\n  margin-right: 3rem;\r\n}\r\n\r\n.progressBarContainer {\r\n  width: 78%;\r\n  margin-left: 1rem;\r\n}\r\n\r\n.alert {\r\n  width: 97%;\r\n  margin: auto;\r\n}\r\n\r\n.form {\r\n  margin: 2rem 0 0 0;\r\n}\r\n\r\n.alertIcon {\r\n  margin: 0 1rem 0 0;\r\n}\r\n\r\n.alertTitle {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.label {\r\n  margin-right: 0.5rem;\r\n}\r\n\r\n.successText {\r\n  margin-top: 1rem;\r\n}\r\n\r\n.notificationContent {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.labelsNotification {\r\n  margin-top: 1rem;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n\r\n  .progressBarContainer {\r\n    width: 73%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n\r\n  .progressBarContainer {\r\n    width: 65%;\r\n  }\r\n\r\n  .notificationContent {\r\n    display: block;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"issues": `ValidationReport_issues__dhVA8`,
	"formTextLabel": `ValidationReport_formTextLabel__kRDiZ`,
	"summaryStatusContainer": `ValidationReport_summaryStatusContainer__LLtzk`,
	"summaryStatusLabel": `ValidationReport_summaryStatusLabel__lYsHT`,
	"progressBarContainer": `ValidationReport_progressBarContainer__smqbr`,
	"alert": `ValidationReport_alert__bMcDU`,
	"form": `ValidationReport_form__4B9t+`,
	"alertIcon": `ValidationReport_alertIcon__qhmbG`,
	"alertTitle": `ValidationReport_alertTitle__vlEUr`,
	"label": `ValidationReport_label__DA-9U`,
	"successText": `ValidationReport_successText__2YRAM`,
	"notificationContent": `ValidationReport_notificationContent__v6Udk`,
	"labelsNotification": `ValidationReport_labelsNotification__GVR8Z`
};
export default ___CSS_LOADER_EXPORT___;
