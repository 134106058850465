// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testScriptViewer_operationCardContainer__3pLNA {
    background-color: var(--gray);
    border-radius: 1rem;
    color: var(--secondary-color);
    border: outset;
    height: 22rem;
}

.testScriptViewer_assertCardContainer__yAWYS {
    background-color: var(--lightGray);
    border-radius: 1rem;
    border: outset;
    height: 15rem;
}

.testScriptViewer_description__KQFTG {
    max-height: 6.5rem;
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    padding-right: 0.5rem;
}

.testScriptViewer_description__KQFTG::-webkit-scrollbar {
    width: 0.4rem;
    background: var(--white);
}

.testScriptViewer_description__KQFTG::-webkit-scrollbar-track {
    border-radius: 0.5rem;
}

.testScriptViewer_description__KQFTG::-webkit-scrollbar-thumb {
    background: #A9A9A9;
}

.testScriptViewer_description__KQFTG::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}

.testScriptViewer_scrollable__EObcD {
    max-height: 48rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.testScriptViewer_scrollable__EObcD::-webkit-scrollbar {
    width: 0.4rem;
    background: var(--lightGray);
}

.testScriptViewer_scrollable__EObcD::-webkit-scrollbar-track {
    border-radius: 0.5rem;
}

.testScriptViewer_scrollable__EObcD::-webkit-scrollbar-thumb {
    background: var(--gray);
}

.testScriptViewer_scrollable__EObcD::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/testScriptViewer/testScriptViewer.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,6BAA6B;IAC7B,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,kCAAkC;IAClC,mBAAmB;IACnB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".operationCardContainer {\r\n    background-color: var(--gray);\r\n    border-radius: 1rem;\r\n    color: var(--secondary-color);\r\n    border: outset;\r\n    height: 22rem;\r\n}\r\n\r\n.assertCardContainer {\r\n    background-color: var(--lightGray);\r\n    border-radius: 1rem;\r\n    border: outset;\r\n    height: 15rem;\r\n}\r\n\r\n.description {\r\n    max-height: 6.5rem;\r\n    overflow-y: auto;\r\n    text-overflow: ellipsis;\r\n    white-space: pre-wrap;\r\n    padding-right: 0.5rem;\r\n}\r\n\r\n.description::-webkit-scrollbar {\r\n    width: 0.4rem;\r\n    background: var(--white);\r\n}\r\n\r\n.description::-webkit-scrollbar-track {\r\n    border-radius: 0.5rem;\r\n}\r\n\r\n.description::-webkit-scrollbar-thumb {\r\n    background: #A9A9A9;\r\n}\r\n\r\n.description::-webkit-scrollbar-thumb:hover {\r\n    background: var(--secondary-color);\r\n}\r\n\r\n.scrollable {\r\n    max-height: 48rem;\r\n    overflow-y: auto;\r\n    overflow-x: hidden;\r\n}\r\n\r\n.scrollable::-webkit-scrollbar {\r\n    width: 0.4rem;\r\n    background: var(--lightGray);\r\n}\r\n\r\n.scrollable::-webkit-scrollbar-track {\r\n    border-radius: 0.5rem;\r\n}\r\n\r\n.scrollable::-webkit-scrollbar-thumb {\r\n    background: var(--gray);\r\n}\r\n\r\n.scrollable::-webkit-scrollbar-thumb:hover {\r\n    background: var(--secondary-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"operationCardContainer": `testScriptViewer_operationCardContainer__3pLNA`,
	"assertCardContainer": `testScriptViewer_assertCardContainer__yAWYS`,
	"description": `testScriptViewer_description__KQFTG`,
	"scrollable": `testScriptViewer_scrollable__EObcD`
};
export default ___CSS_LOADER_EXPORT___;
