// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_mainHomeContainer__rM6xM {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Home_homeimageIcon__Satfa {
  width: 25%;
  height: auto;
  margin-right: 4rem;
}

@media screen and (max-width: 991px) {
  .Home_mainHomeContainer__rM6xM {
    flex-direction: column;
  }

  .Home_homeimageIcon__Satfa {
    width: auto;
    height: auto;
    max-height: 40vh;
    margin-right: 0;
  }
}

`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".mainHomeContainer {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100%;\r\n}\r\n\r\n.homeimageIcon {\r\n  width: 25%;\r\n  height: auto;\r\n  margin-right: 4rem;\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n  .mainHomeContainer {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .homeimageIcon {\r\n    width: auto;\r\n    height: auto;\r\n    max-height: 40vh;\r\n    margin-right: 0;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainHomeContainer": `Home_mainHomeContainer__rM6xM`,
	"homeimageIcon": `Home_homeimageIcon__Satfa`
};
export default ___CSS_LOADER_EXPORT___;
