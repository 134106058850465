import { FhirHTTPCodes, FhirStatus, StatusAlert, StatusCode, StatusIcon, Title } from "@fyrstain/fhir-front-library";
import Client from "fhir-kit-client";
import { Parameters, ParametersParameter } from "fhir/r5";
import i18n from "i18next";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import ReactJson from 'react-json-view';
import { useNavigate, useParams } from "react-router-dom";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import styles from './exchangeDetails.module.css';

const Exchangedetails: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [loading, setLoading] = useState<boolean>(false); const { exchangeId } = useParams<{ exchangeId: string }>();
    const [method, setMethod] = useState("");
    const [path, setPath] = useState("");
    const [proxyChannel, setProxyChannel] = useState("");
    const [sender, setSender] = useState("");
    const [statusResponse, setStatusResponse] = useState("");
    const [partHeaderRequest, setPartHeaderRequest] = useState([] as ParametersParameter[]);
    const [partHeaderResponse, setPartHeaderResponse] = useState([] as ParametersParameter[]);
    const [bodyRequest, setBodyRequest] = useState(parseJson(""));
    const [bodyResponse, setBodyResponse] = useState(parseJson(""));

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    const navigate = useNavigate();

    useEffect(() => {
        loadPage();
    }, []);

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * Load exchange details from the back to populate the table.
     * 
     * @returns the promise of a Parameters.
     */
    async function loadExchangeDetails() {
        try {
            const response = await fhirClient.read({
                resourceType: 'Parameters',
                id: exchangeId ?? '',
            });
            const parameters = response as Parameters;
            setMethod(getMethod(parameters));
            setPath(getPath(parameters));
            setSender(getSender(parameters));
            setProxyChannel(getProxyChannel(parameters));
            setStatusResponse(getStatus(parameters));
            setPartHeaderRequest(getPartHeaderRequest(parameters));
            setPartHeaderResponse(getPartHeaderResponse(parameters));
            setBodyRequest(getBodyRequest(parameters));
            setBodyResponse(getBodyResponse(parameters));
        } catch (error) {
            onError();
        }
    }

    async function loadPage() {
        setLoading(true);
        await loadExchangeDetails();
        setLoading(false);
    }

    /**
  * Get Method Response to populate the field.
  * 
  * @param parameters    the parameters to the resource.
  */
    function getMethod(parameters: Parameters): string {
        const methodParam = parameters.parameter?.find(p => p.name === "method");
        return methodParam?.valueCode
            ? methodParam?.valueCode
            : methodParam?.valueCode ? methodParam?.valueCode : "N/A";
    }

    /**
    * Get Path Response to populate the field.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getPath(parameters: Parameters): string {
        const pathParam = parameters.parameter?.find(p => p.name === "path");
        return pathParam?.valueUri
            ? pathParam?.valueUri
            : pathParam?.valueUri ? pathParam?.valueUri : "N/A";
    }

    /**
    * Get ProxyChannel Response to populate the field.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getProxyChannel(parameters: Parameters): string {
        const proxyChannelParam = parameters.parameter?.find(p => p.name === "proxyChannel");
        return proxyChannelParam?.valueUri
            ? proxyChannelParam?.valueUri
            : proxyChannelParam?.valueUri ? proxyChannelParam?.valueUri : "N/A";
    }

    /**
    * Get sender Response to populate the field.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getSender(parameters: Parameters): string {
        const senderParam = parameters.parameter?.find(p => p.name === "sender");
        return senderParam?.valueUri
            ? senderParam?.valueUri
            : senderParam?.valueUri ? senderParam?.valueUri : "N/A";
    }

    /**
    * Get status Response to populate the notification.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getStatus(parameters: Parameters): string {
        const statusParam = parameters.parameter?.find(p => p.name === "responseStatus")?.valueCode;
        return statusParam ? statusParam : "N/A";
    }

    /**
    * Get part header request to populate the table.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getPartHeaderRequest(parameters: Parameters) {
        const result: ParametersParameter[] = [];
        parameters.parameter?.forEach(p => {
            if (p.name === "requestHeader") {
                p.part?.forEach(part => {
                    result.push(part);
                });
            }
        });
        return result;
    }

    /**
* Get part header response to populate the table.
* 
* @param parameters    the parameters to the resource.
*/
    function getPartHeaderResponse(parameters: Parameters) {
        const result: ParametersParameter[] = [];
        parameters.parameter?.forEach(p => {
            if (p.name === "responseHeader") {
                p.part?.forEach(part => {
                    result.push(part);
                });
            }
        });
        return result;
    }

    /**
    * Get body Request to populate the field.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getBodyRequest(parameters: Parameters): string {
        const bodyRequestParam = parameters.parameter?.find(p => p.name === "requestBody");
        return bodyRequestParam?.valueString
            ? bodyRequestParam?.valueString
            : bodyRequestParam?.valueString ? bodyRequestParam?.valueString : "N/A";
    }

    /**
    * Get body Response to populate the table.
    * 
    * @param parameters    the parameters to the resource.
    */
    function getBodyResponse(parameters: Parameters): string {
        const bodyResponseParam = parameters.parameter?.find(p => p.name === "responseBody");
        return bodyResponseParam?.valueString
            ? bodyResponseParam?.valueString
            : bodyResponseParam?.valueString ? bodyResponseParam?.valueString : "N/A";
    }

    function parseJson(response: string) {
        try {
            return JSON.parse(response);
        } catch (ex) {
            return "{}";
        };
    };

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    return (
        <PandoraPage titleKey='Message' loading={loading} needsLogin={true} >
            <>
                <div className="section">
                    <Card className={styles.card}>
                        <Card.Header>
                            <Title level={2} content={'Informations'} />
                        </Card.Header>
                        <Card.Body className="cardBody">
                            <div>
                                <div className="spaceBetweenContainer">
                                    <div className="mediumContainer">
                                        <div>
                                            <Form.Label className={styles.label}>
                                                <strong >
                                                    ID :
                                                </strong>
                                            </Form.Label>
                                            <Form.Text>
                                                {exchangeId}
                                            </Form.Text>
                                        </div>
                                        <div>
                                            <Form.Label className={styles.label}>
                                                <strong>
                                                    {i18n.t('label.method')} :
                                                </strong>
                                            </Form.Label>
                                            <Form.Text>
                                                {method}
                                            </Form.Text>
                                        </div>
                                    </div>
                                    <div className="mediumContainer">
                                        <div>
                                            <Form.Label className={styles.label}>
                                                <strong>
                                                    {i18n.t('label.sender')} :
                                                </strong>
                                            </Form.Label>
                                            <Form.Text>
                                                {sender}
                                            </Form.Text>
                                        </div>
                                        <div>
                                            <Form.Label className={styles.label}>
                                                <strong>
                                                    {i18n.t('label.path')} :
                                                </strong>
                                            </Form.Label>
                                            <Form.Text>
                                                {path}
                                            </Form.Text>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Form.Label className={styles.label}>
                                        <strong>
                                            {i18n.t('label.proxychannel')} :
                                        </strong>
                                    </Form.Label>
                                    <Form.Text>
                                        {proxyChannel}
                                    </Form.Text>
                                </div>
                            </div>
                            <div>
                                <StatusAlert
                                    status={FhirStatus[statusResponse as keyof typeof FhirStatus]}
                                >
                                    <div>
                                        <div>
                                            <StatusIcon
                                                status={FhirStatus[statusResponse as keyof typeof FhirStatus]}
                                            />
                                            <StatusCode
                                                status={statusResponse}
                                                statusCode={FhirHTTPCodes[statusResponse as keyof typeof FhirHTTPCodes]}
                                            />
                                        </div>
                                    </div>
                                </StatusAlert>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="section">
                    <Card className={styles.card}>
                        <Card.Header>
                            <Title level={2} content={i18n.t("title.headers")} />
                        </Card.Header>
                        <Card.Body className="cardBody">
                            <div className="spaceBetweenContainerStart">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className={styles.headerTableContainer}>
                                            <th className={styles.tableTitle}
                                                colSpan={2}
                                            >
                                                <Title level={3} content={i18n.t("title.request")} />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                {i18n.t('label.name')}
                                            </th>
                                            <th>
                                                {i18n.t('label.value')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partHeaderRequest.map((p) => (
                                            <tr>
                                                <td>
                                                    {p.name}
                                                </td>
                                                <td>
                                                    {p.valueString}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Table striped bordered hover className={styles.table}>
                                    <thead>
                                        <tr className={styles.headerTableContainer}>
                                            <th
                                                className={styles.tableTitle}
                                                colSpan={2}
                                            >
                                                <Title level={3} content={i18n.t("title.response")} />
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                {i18n.t('label.name')}
                                            </th>
                                            <th>
                                                {i18n.t('label.value')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partHeaderResponse.map((p) => (
                                            <tr>
                                                <td>
                                                    {p.name}
                                                </td>
                                                <td>
                                                    {p.valueString}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="section">
                    <Card>
                        <Card.Header>
                            <Title level={2} content={i18n.t("title.body")} />
                        </Card.Header>
                        <Card.Body>
                            <Card>
                                <Card.Header>
                                    <Title level={3} content={i18n.t("title.request")} />
                                </Card.Header>
                                {bodyRequest === "N/A" &&
                                    <Card.Body>
                                        {bodyRequest}
                                    </Card.Body>
                                }
                                {bodyRequest !== "N/A" &&
                                    <Card.Body>
                                        <ReactJson src={JSON.parse(bodyRequest)} name={false} />
                                    </Card.Body>
                                }
                            </Card>
                            <div className="section">
                                <Card>
                                    <Card.Header>
                                        <Title level={3} content={i18n.t("title.response")}/>
                                    </Card.Header>
                                    {bodyResponse === "N/A" &&
                                        <Card.Body>
                                            {bodyResponse}
                                        </Card.Body>
                                    }
                                    {bodyResponse !== "N/A" &&
                                        <Card.Body>
                                            <ReactJson src={JSON.parse(bodyResponse)} name={false} />
                                        </Card.Body>
                                    }
                                </Card>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </>
        </PandoraPage>
    );
};

export default Exchangedetails;

