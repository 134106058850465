import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { useEffect } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import Error from "./pages/Error/Error";
import InProgress from "./pages/InProgress/InProgress";
import TestPlanReports from "./pages/TestPlanReports/TestPlanReports";
import TestPlans from "./pages/TestPlans/TestPlans";
import Exchanges from "./pages/exchanges/Exchanges";
import Home from "./pages/home/Home";
import SystemRegistration from "./pages/systemRegistration/SystemRegistration";
import Systems from "./pages/systems/Systems";
import SystemsSelection from "./pages/systemsSelection/SystemsSelection";
import TestPlanReport from "./pages/testPlanReport/TestPlanReport";
import TestPlanSystemsSelection from "./pages/testPlanSystemsSelection/TestPlanSystemsSelection";
import TestPlanViewer from "./pages/testPlanViewer/TestPlanViewer";
import TestReportDetails from "./pages/testReportDetails/TestReportDetails";
import TestReports from "./pages/testReports/TestReports";
import TestScripts from "./pages/testScripts/TestScripts";
import ValidationReport from "./pages/validationReport/ValidationReport";
import ValidationReports from "./pages/validationReports/ValidationReports";
import ValidatorHome from "./pages/validatorHome/ValidatorHome";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import AcesoError from "./pages/Aceso/Error/Error";
// import AcesoHome from "./pages/Aceso/Home/Home";
// import AcesoInProgress from "./pages/Aceso/InProgress/InProgress";
// import JunoError from "./pages/Juno/Error/Error";
// import JunoHome from "./pages/Juno/Home/Home";
// import JunoInProgress from "./pages/Juno/InProgress/InProgress";
// import VulcanError from "./pages/Vulcan/Error/Error";
// import VulcanHome from "./pages/Vulcan/Home/Home";
// import VulcanInProgress from "./pages/Vulcan/InProgress/InProgress";
import ExchangeDetails from "./pages/exchangeDetails/ExchangeDetails";
// import MyTestPlans from "./pages/myTestPlans/MyTestPlans";
import TestScriptViewer from "./pages/testScriptViewer/TestScriptViewer";
// import PatientsViewer from "./pages/Aceso/PatientsViewer/PatientsViewer"
// import PatientsList from "./pages/Aceso/PatientsList/PatientsList";
// import CreateNote from "./pages/Aceso/CreateNote/CreateNote";
// import PatientCreation from "./pages/Aceso/patientCreation/PatientCreation";
// import NotesPage from "./pages/Aceso/notesPage/NotesPage";
// import CareteamPage from "./pages/Aceso/createamPage/CareteamPage";
import FixtureEdition from "./pages/FixtureEdition/FixtureEdition";
import EvalutionReport from "./pages/evaluationReport/EvaluationReport";
import EvalutionReports from "./pages/evaluationReports/EvaluationReports";
import PackageLoader from "./pages/packageLoader/packageLoader";

require('dayjs/locale/fr');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['fr', 'en'],
  })

dayjs.extend(relativeTime);
dayjs.locale(i18n.language);

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useTranslation();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
      // case "/Juno/Home":
      // case "/Aceso/Home":
      // case "/Vulcan/Home":
      case "/Pandora/Home":
      case "/Home":
        title = "Home page";
        metaDescription = "";
        break;
      // case "/Juno/Error":
      // case "/Aceso/Error":
      // case "/Vulcan/Error":
      case "/Error":
        title = "Oops !";
        metaDescription = "";
        break;
      // case "/Juno/InProgress":
      // case "/Aceso/InProgress":
      // case "/Vulcan/InProgress":
      case "/InProgress":
        title = "Coming Soon";
        metaDescription = "";
        break;
      case "/PackageLoader":
        title = "Package Loader";
        metaDescription = "";
        break;
      case "/ValidatorHome":
        title = "Validator Home";
        metaDescription = "";
        break;
      case "/ValidationReport":
        title = "Validation report";
        metaDescription = "";
        break;
      case "/TestScript":
        title = "Test script";
        metaDescription = "";
        break;
      case "/SystemsSelection":
        title = "Systems Selection";
        metaDescription = "";
        break;
      case "/TestReportDetails":
        title = "Test report details";
        metaDescription = "";
        break;
      case "/SystemRegistration":
        title = "System registration";
        metaDescription = "";
        break;
      case "/SystemEdition":
        title = "System edition";
        metaDescription = "";
        break;
      case "/Systems":
        title = "Systems";
        metaDescription = "";
        break;
      case "/Exchanges":
        title = "Exchanges";
        metaDescription = "";
        break;
      case "/SignIn":
        title = "SignIn";
        metaDescription = "";
        break;
      case "/TestPlans":
        title = "TestPlans";
        metaDescription = "";
        break;
      case "/TestPlanDetails":
        title = "TestPlan viewer";
        metaDescription = "";
        break;
      case "/TestPlan/SystemSelection/:testPlanId":
        title = "TestPlan System Selection";
        metaDescription = "";
        break;
      case "/TestPlan/Report/:testPlanId":
        title = "TestPlan report";
        metaDescription = "";
        break;
      case "/TestPlan/Report":
        title = "TestPlan report list";
        metaDescription = "";
        break;
      case "/ExchangeDetails/:exchangeId":
        title = "Exchange details";
        metaDescription = "";
        break;
      case "/ValidationReports":
        title = "Validation reports";
        metaDescription = "";
        break;
      case "/TestScriptViewer/:testScriptId":
        title = "Test script viewer";
        metaDescription = "";
        break;
      case "EvaluationReport/:evaluationReportId":
        title = "Evaluation report";
        metaDescription = "";
        break;
      case "EvaluationReports":
        title = "Evaluation reports";
        metaDescription = "";
        break;
      // case "/MyTestPlans":
      //   title = "My Test Plans";
      //   metaDescription = "";
      //   break;
      // case "/Aceso/PatientsViewer":
      //   title = "My Test Plans";
      //   metaDescription = "";
      //   break;
      // case "/Aceso/PatientsList":
      //   title = "Patients List";
      //   metaDescription = "";
      //   break;
      // case "/Aceso/PatientCreation":
      //   title = "Patient Creation";
      //   metaDescription = "";
      //   break;
      // case "/Aceso/NotesPage":
      //   title = "Notes Page";
      //   metaDescription = "";
      //   break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/Home"
        element={<Home />}
      />
      <Route
        path="Pandora/Home"
        element={<Home />}
      />
      <Route
        path="/Error"
        element={<Error />}
      />
      <Route
        path="/InProgress"
        element={<InProgress />}
      />
      <Route
        path="/ValidatorHome/:validatorParam"
        element={<ValidatorHome />}
      />
      <Route
        path="/PackageLoader/:validatorParam"
        element={<PackageLoader />}
      />
      <Route
        path="/ValidationReport/:validationReportId"
        element={<ValidationReport />}
      />
      <Route
        path="/TestScript"
        element={<TestScripts />}
      />
      <Route
        path="/SystemsSelection/:testScriptId"
        element={<SystemsSelection />}
      />
      <Route
        path="/TestPlan/SystemSelection/:testPlanId"
        element={<TestPlanSystemsSelection />}
      />
      <Route
        path="/TestReports"
        element={<TestReports />}
      />
      <Route
        path="/TestReportDetails/:testReportId"
        element={<TestReportDetails />}
      />
      <Route
        path="/SystemRegistration"
        element={<SystemRegistration />}
      />
      <Route
        path="/SystemEdition/:systemId"
        element={<SystemRegistration />}
      />
      <Route
        path="/Systems"
        element={<Systems />}
      />
      <Route
        path="/Exchanges"
        element={<Exchanges />}
      />
      <Route
        path="/TestPlans"
        element={<TestPlans />}
      />
      <Route
        path="/TestPlanDetails/:testPlanId"
        element={<TestPlanViewer />}
      />
      <Route
        path="/TestPlan/SystemSelection/:testPlanId"
        element={<TestPlanSystemsSelection />}
      />
      <Route
        path="/TestPlan/Report"
        element={<TestPlanReports />}
      />
      <Route
        path="/TestPlan/Report/:testPlanId"
        element={<TestPlanReport />}
      />
      <Route
        path="/ExchangeDetails/:exchangeId"
        element={<ExchangeDetails />}
      />
      <Route
        path="/ValidationReports"
        element={<ValidationReports />}
      />
      <Route
        path="/TestScriptViewer/:testScriptId"
        element={<TestScriptViewer />}
      />
      {/* <Route
        path="/MyTestPlans"
        element={<MyTestPlans />}
      /> */}
      <Route
        path="/FixtureEdition/:fixtureId"
        element={<FixtureEdition />}
      />
      <Route
        path="/EvaluationReport/:evaluationReportId"
        element={<EvalutionReport />}
      />
      <Route
        path="/EvaluationReports"
        element={<EvalutionReports />}
      />
      {/* Aceso */}
      {/* <Route
        path="/Aceso/Home"
        element={<AcesoHome />}
      />
      <Route
        path="/Aceso/InProgress"
        element={<AcesoInProgress />}
      />
      <Route
        path="/Aceso/Error"
        element={<AcesoError />}
      />
      <Route
        path="/Aceso/PatientsViewer/:patientId"
        element={<PatientsViewer />}
      />
      <Route
        path="/Aceso/PatientsList"
        element={<PatientsList />}
      />
      <Route
        path="/Aceso/create-note"
        element={<CreateNote />}
      />
      <Route
        path="/Aceso/PatientCreation"
        element={<PatientCreation />}
      />
      <Route
        path="/Aceso/NotesPage"
        element={<NotesPage />}
      />
      <Route
        path="/Aceso/CareTeamPage"
        element={<CareteamPage />}
      /> */}

      {/* Juno */}
      {/* <Route
        path="/Juno/Home"
        element={<JunoHome />}
      />
      <Route
        path="/Juno/InProgress"
        element={<JunoInProgress />}
      />
      <Route
        path="/Juno/Error"
        element={<JunoError />}
      /> */}

      {/* Vulcan */}
      {/* <Route
        path="/Vulcan/Home"
        element={<VulcanHome />}
      />
      <Route
        path="/Vulcan/InProgress"
        element={<VulcanInProgress />}
      />
      <Route
        path="/Vulcan/Error"
        element={<VulcanError />}
      /> */}

    </Routes>
  );
}

export default App;
