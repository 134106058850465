// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testReportDetails_alert__xPA0R {
    width: 97%;
}

.testReportDetails_noOperationCard__\\+V1kA {
    background-color: white !important;
    height: 5.2rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testReportDetails/testReportDetails.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,kCAAkC;IAClC,cAAc;AAClB","sourcesContent":[".alert {\r\n    width: 97%;\r\n}\r\n\r\n.noOperationCard {\r\n    background-color: white !important;\r\n    height: 5.2rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `testReportDetails_alert__xPA0R`,
	"noOperationCard": `testReportDetails_noOperationCard__+V1kA`
};
export default ___CSS_LOADER_EXPORT___;
