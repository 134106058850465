// Font awesome
import { faCheck, faEye, faScaleBalanced, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Fhir front library
import { FhirStatus, SearchableTable, StatusTag } from '@fyrstain/fhir-front-library';
// Date
import dayjs from 'dayjs';
// FHIR
import Client from 'fhir-kit-client';
import { Bundle, BundleEntry, OperationOutcome, Parameters } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useState } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Component
import PandoraPage from '../../components/PandoraPage/PandoraPage';
// Style
import styles from './validationReports.module.css';

const ValidationReports: FunctionComponent = () => {

    ////////////////////////////////
    //            State           //
    ////////////////////////////////

    const [loading, setLoading] = useState(false);

    ////////////////////////////////
    //          Client           //
    ////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
    });

    /////////////////////////////////////
    //            Navigation           //
    /////////////////////////////////////

    const navigate = useNavigate();

    /**
     * Navigate to the test report details page if exist.
     */
    const handleIconClick = async (id: string, event: any) => {
        event.stopPropagation();
        const response = await fhirClient.search({
            resourceType: 'TestReport',
            searchParams: {
                _elements: '_id',
                _filter: 'report-test-link sw "Bundle/' + id + '" or report-setup-link sw "Bundle/' + id + '"'
            }
        });
        if (response.total > 0) {
            navigate("/TestReportDetails/" + response.entry?.[0].resource?.id);
        }
    };

    const onValidationReportDetails = useCallback((id: string) => {
        navigate("/ValidationReport/" + id);
    }, [navigate]);

    /////////////////////////////////////
    //             Error               //
    /////////////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //           Functions             //
    /////////////////////////////////////

    /**
    * Get test report to populate the table.
    * 
    * @param bundle    the bundle to the Validation report.
    */
    async function getTestReport(bundle: Bundle) {
        const idMap = new Map<string, string | undefined>();
        const validationReportId = bundle.id || '';
        const response = await fhirClient.search({
            resourceType: 'TestReport',
            searchParams: {
                _elements: '_id',
                _filter: 'report-test-link sw "Bundle/' + validationReportId + '" or report-setup-link sw "Bundle/' + validationReportId + '"'
            }
        });
        response.entry?.forEach((entry: BundleEntry) => {
            if (entry.resource?.resourceType === 'TestReport') {
                idMap.set(validationReportId, entry.resource.id);
            }
        });
        return idMap;
    }

    /**
   * Get issued to populate the field.
   * 
   * @param bundle    the bundle to the Validation report.
   */
    function getIssued(bundle: Bundle): string {
        let issued = 'N/A';
        issued = bundle.meta?.lastUpdated ? bundle.meta?.lastUpdated : issued;
        return issued;
    }

    /**Bundle
    * Get profile URL to populate the field.
    * 
    * @param bundle    the bundle to the Validation report.
    */
    function getProfileUrl(bundle: Bundle): string {
        let profileURL = 'N/A';
        bundle.entry?.forEach(entry => {
            if ('Parameters' === entry.resource?.resourceType) {
                const parameters: Parameters = entry.resource as Parameters;
                const profileParam = parameters.parameter?.find(p => p.name === "profile");
                profileURL = profileParam?.valueCanonical
                    ? profileParam?.valueCanonical
                    : profileParam?.valueUrl ? profileParam?.valueUrl : "N/A";
            }
        })
        return profileURL;
    }

    /**
    * Get validation status to populate the table.
    * 
    * @param bundle    the bundle to the Validation report.
    */
    function getValidationStatus(bundle: Bundle): string {
        let validationStatus = 'N/A';
        bundle.entry?.forEach(entry => {
            if ('OperationOutcome' === entry.resource?.resourceType) {
                const operationOutcome: OperationOutcome = entry.resource as unknown as OperationOutcome;
                const code = operationOutcome.issue
                    ?.find(issue => issue.details?.coding?.find(coding => coding.code && (coding.system === "http://isis.com/test/CodeSystem/COS-ValidateStatus" || coding.system === "http://hl7.org/fhir/ValueSet/verificationresult-validation-status")))
                    ?.details?.coding?.find(coding => coding.code && (coding.system === "http://isis.com/test/CodeSystem/COS-ValidateStatus" || coding.system === "http://hl7.org/fhir/ValueSet/verificationresult-validation-status"))?.code;
                validationStatus = code ? code : validationStatus;
            }
        })
        return validationStatus;
    }

    /////////////////////////////////
    //           Content           //
    /////////////////////////////////

    return (
        <PandoraPage titleKey='title.validationreports' loading={loading} needsLogin={true} >
            <>
                <SearchableTable
                    searchCriteriaProperties={{
                        title: i18n.t('title.searchcriteria'),
                        submitButtonLabel: i18n.t('button.search'),
                        resetButtonLabel: i18n.t('button.reset'),
                        language: i18n.t,
                        fixedParameters:
                        {
                            _sort: "-_lastUpdated",
                            "composition.type": "validation-report"
                        },
                        inputs: [
                            {
                                label: "ID",
                                type: "text",
                                placeholder: i18n.t('placeholder.id'),
                                searchParamsName: "_id",
                            },
                            {
                                label: i18n.t('label.profileurl'),
                                type: "text",
                                placeholder: i18n.t('placeholder.profileurl'),
                                searchParamsName: "profile-url",
                            },
                        ],
                    }}
                    paginatedTableProperties={{
                        columns: [
                            {
                                header: i18n.t('label.intest?'),
                                dataField: 'testReport',
                                width: "10%",
                                formatter: (cell: string | undefined) => {
                                    return <div className={styles.iconContainer}>
                                        {cell && cell.length > 0 ? <FontAwesomeIcon
                                            className={styles.checkIcon}
                                            icon={faCheck}
                                        /> : <FontAwesomeIcon
                                            className={styles.errorIcon}
                                            icon={faXmark}
                                        />
                                        }
                                    </div>;
                                },
                            },
                            {
                                header: "ID",
                                dataField: "id",
                                width: "25%",
                                tabletWidth: "18%",
                            },
                            {
                                header: i18n.t('label.issued'),
                                dataField: 'issued',
                                width: "10%",
                                formatter: (cell: string) => {
                                    return <>{dayjs(cell).fromNow()}</>;
                                },
                            },
                            {
                                header: i18n.t('label.profileurl'),
                                dataField: 'profileUrl',
                                width: "30%",
                                tabletWidth: "20%",
                            },
                            {
                                header: i18n.t('label.status'),
                                dataField: 'validationStatus',
                                width: "15%",
                                tabletWidth: "20%",
                                formatter: (cell: keyof typeof FhirStatus) => {
                                    return (
                                        <StatusTag
                                            status={FhirStatus[cell]}
                                            statusMessage={cell}
                                        />
                                    );
                                },
                            },
                        ],
                        action: [
                            {
                                icon: faEye,
                                onClick: onValidationReportDetails,
                            },
                            {
                                icon: faScaleBalanced,
                                onClick: handleIconClick,
                                display: (item: any) => item.testReport !== undefined,
                            }
                        ],
                        mapResourceToData: async (resource: any) => {
                            const bundle = resource as Bundle;
                            const testReportAsync = await getTestReport(bundle);
                            return {
                                id: resource.id,
                                issued: getIssued(resource),
                                profileUrl: getProfileUrl(resource),
                                validationStatus: getValidationStatus(resource),
                                testReport: testReportAsync.get(resource.id),
                            };
                        },
                        searchProperties: {
                            serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                            resourceType: 'Bundle',
                        },
                        onError: onError,
                    }}
                />
            </>
        </PandoraPage>
    );
}

export default ValidationReports;