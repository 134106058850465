// Font awesome
import { faEye } from "@fortawesome/free-solid-svg-icons";
// Fhir front library
import { FhirStatus, SearchableTable, StatusTag } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Component
import PandoraPage from '../../components/PandoraPage/PandoraPage';

const TestPlanReports: FunctionComponent = () => {

    //////////////////////////////
    //        Constants         //
    //////////////////////////////

    //TODO Also add for testCase ! 
    const statusSystem = "https://fyrstain.com/testPlanStatus";
    const resultSystem = "https://fyrstain.com/testPlanResult";

    //////////////////////////////
    //        Navigation        //
    //////////////////////////////

    const navigate = useNavigate();

    const onDetail = useCallback((id: string) => {
        navigate("/TestPlan/Report/" + id);
    }, [navigate]);

    //////////////////////////////
    //           Error          //
    //////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    //////////////////////////////
    //         Functions        //
    //////////////////////////////

    /**
     * Get the status of the test plan
     * @param testPlan
     */
    function getStatus(testPlan: any): string {
        return testPlan.testCase?.flatMap((testCase: any) => testCase.assertion ?? [])
            .flatMap((assertion: any) => assertion.type ?? [])
            .flatMap((type: any) => type.coding ?? [])
            .find((coding: any) => coding.system === statusSystem)?.code ?? 'unknown';
    }

    /**
    * Get the result of the test plan
    * @param testPlan
    */
    function getResult(testPlan: any): string {
        return testPlan.testCase?.flatMap((testCase: any) => testCase.assertion ?? [])
            .flatMap((assertion: any) => assertion.type ?? [])
            .flatMap((type: any) => type.coding ?? [])
            .find((coding: any) => coding.system === resultSystem)?.code ?? 'unknown';
    }

    //////////////////////////////
    //          Content         //
    //////////////////////////////

    return (
        <PandoraPage titleKey='title.testplanreports' needsLogin={true}>
            <SearchableTable
                searchCriteriaProps={{
                    title: i18n.t('title.searchcriteria'),
                    primaryButtonLabel: i18n.t('button.search'),
                    secondaryButtonLabel: i18n.t('button.reset'),
                    language: i18n.t,
                    searchResultModifier:
                    {
                        _elements: "id,title,status,testCase",
                        _sort: "-_lastUpdated",
                        _tag: "https://fyrstain.com/testPlanType|Instance",
                    },
                    criteria: [
                        {
                            inputProps: {
                                label: "ID",
                                type: "text",
                            },
                            searchParamsName: "_id",
                        },
                        {
                            inputProps: {
                                label: i18n.t('label.name'),
                                type: "text",
                            },
                            searchParamsName: "title:contains",
                        },
                    ],
                }}
                paginationTableProps={{
                    columns: [
                        {
                            header: "ID",
                            dataField: "id",
                            width: "25%",
                        },
                        {
                            header: i18n.t('label.name'),
                            dataField: "title",
                            width: "30%",
                        },
                        {
                            header: i18n.t('label.status'),
                            dataField: "status",
                            width: "15%",
                            tabletWidth: "20%",
                            formatter: (cell: keyof typeof FhirStatus) => {
                                return <StatusTag
                                    status={FhirStatus[cell]}
                                    statusMessage={cell}
                                />;
                            },
                        },
                        {
                            header: i18n.t('label.result'),
                            dataField: "result",
                            width: "15%",
                            tabletWidth: "20%",
                            formatter: (cell: keyof typeof FhirStatus) => {
                                return <StatusTag
                                    status={FhirStatus[cell]}
                                    statusMessage={cell}
                                />;
                            },

                        },
                    ],
                    action: [
                        {
                            icon: faEye,
                            onClick: onDetail,
                        },
                    ],
                    mapResourceToData: (resource: any) => {
                        return {
                            id: resource.id,
                            title: resource.title,
                            status: getStatus(resource),
                            result: getResult(resource),
                        };
                    },
                    searchProps: {
                        serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                        resourceType: 'TestPlan',
                    },
                    onError: onError,
                }}
            />
        </PandoraPage>
    );
};

export default TestPlanReports;