import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Client from 'fhir-kit-client';
import { Bundle, Device, TestPlan, TestReport } from "fhir/r5";
import i18n from "i18next";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import { useNavigate, useParams } from "react-router-dom";
import PandoraPage from '../../components/PandoraPage/PandoraPage';
import { Title, StatusTag, FhirStatus } from "@fyrstain/fhir-front-library";
import styles from "./testPlanReport.module.css";

const TestPlanReport: FunctionComponent = () => {

    /////////////////////////////////////
    //      Constants / ValueSet       //
    /////////////////////////////////////

    const { testPlanId } = useParams();
    //TODO Also add for testCase ! 
    const statusSystem = "https://fyrstain.com/testPlanStatus";
    const resultSystem = "https://fyrstain.com/testPlanResult";

    const [columns, setColumns] = useState([
        { text: "ID", dataField: "id" },
        { text: i18n.t('label.name'), dataField: "name" },
        {
            text: i18n.t('label.status'),
            dataField: "status",
            formatter: (cell: keyof typeof FhirStatus) => {
                return <StatusTag
                    status={FhirStatus[cell]}
                    statusMessage={cell}
                />;
            }
        },
        {
            text: i18n.t('label.result'),
            dataField: "result",
            formatter: (cell: keyof typeof FhirStatus) => {
                return <StatusTag
                    status={FhirStatus[cell]}
                    statusMessage={cell}
                />;
            }
        },
        {
            text: "Action",
            dataField: "id",
            formatter: (cell: string) => {
                return <div>
                    <FontAwesomeIcon
                        icon={faEye}
                        className="actionIcon"
                        aria-disabled={true}
                        onClick={event => onReport(cell)}
                    />
                </div>;
            },
        }
    ] as ColumnDescription[]);

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([] as {}[]);
    const [testPlanName, setTestPlanName] = useState("");
    const [testPlanDescription, setTestPlanDescription] = useState("");
    const [testPlanVersion, setTestPlanVersion] = useState("");
    const [testPlanStatus, setTestPlanStatus] = useState("");
    const [testPlanResult, setTestPlanResult] = useState("");

    const [serverId, setServerId] = useState("");
    const [serverLabel, setServerLabel] = useState("");

    const [clientId, setClientId] = useState("");
    const [clientLabel, setClientLabel] = useState("");

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setLoading(true);
        await loadTestPlanInformations();
        setLoading(false);
    }

    /**
    * Load the TestScript informations to display in fields.
    */
    async function loadTestPlanInformations() {
        try {
            const loadedData: {}[] = []
            const response = await fhirClient.search({
                resourceType: "TestPlan",
                searchParams: {
                    "_id": testPlanId ?? "",
                    "_include": ["TestPlan:testscript", "TestPlan:scope", "TestPlan:result"],
                }
            });
            if (response.resourceType !== 'Bundle') {
                throw Error(response.statusText);
            }
            const bundle: Bundle = response as Bundle;

            bundle.entry?.forEach(entry => {
                if ('TestPlan' === entry.resource?.resourceType) {
                    const testPlan: TestPlan = entry.resource as TestPlan;
                    setTestPlanName(testPlan.title ?? "undefined");
                    setTestPlanDescription(testPlan.description ?? "undefined");
                    setTestPlanVersion(testPlan.version ?? "undefined");

                    const testPlanStatus = testPlan.testCase?.map(testCase => testCase.assertion)
                        .map(assertions => assertions?.find(assertion => assertion.type?.find(type => type.coding?.find(coding => coding.system === statusSystem))))
                        .map(assertion => assertion?.type?.find(type => type.coding?.find(coding => coding.system === statusSystem)))
                        .map(type => type?.coding?.find(coding => coding.system === statusSystem)?.code).at(0);

                    setTestPlanStatus(testPlanStatus ?? "unknown");

                    const testPlanResult = testPlan.testCase?.map(testCase => testCase.assertion)
                        .map(assertions => assertions?.find(assertion => assertion.type?.find(type => type.coding?.find(coding => coding.system === resultSystem))))
                        .map(assertion => assertion?.type?.find(type => type.coding?.find(coding => coding.system === resultSystem)))
                        .map(type => type?.coding?.find(coding => coding.system === resultSystem)?.code).at(0);

                    setTestPlanResult(testPlanResult ?? "unknown");
                } else if ('TestReport' === entry.resource?.resourceType) {
                    const report: TestReport = entry.resource as TestReport;
                    loadedData.push({
                        "id": report.id,
                        "name": report.name,
                        "status": report.status,
                        "result": report.result
                    });
                    report.participant?.forEach(participant => {
                        const device = bundle.entry?.find(e => e.resource?.resourceType === 'Device' && e.resource.displayName?.toLowerCase() === participant.display?.toLowerCase());
                        let systemType = 'N/A';
                        if (device?.resource) {
                            systemType = (device.resource as Device).type?.[0]?.coding?.find(coding => coding.system === 'http://fyrstain.com/pdt/ValueSet/systemTypes')?.display ?? 'N/A';
                        }
                        if ((participant.type === 'client' || participant.type === 'server') && (systemType === 'SUT' || systemType === 'Simulator')) {
                            if (participant.type === 'client') {
                                setClientId(participant.uri ?? 'N/A');
                                setClientLabel((participant.display ?? 'N/A') + ' / ' + systemType);
                            } else {
                                setServerId(participant.uri ?? 'N/A');
                                setServerLabel((participant.display ?? 'N/A') + ' / ' + systemType);
                            }
                        }
                    });
                }
            });
            setData(loadedData);
        } catch (error) {
            console.log(error);
            onError();
        }
        return [];
    }

    const navigate = useNavigate();

    const onReport = useCallback((id: string) => {
        navigate("/TestReportDetails/" + id);
    }, [navigate]);

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    return (
        <PandoraPage titleKey='title.testplanreport' loading={loading} needsLogin={true} >
            <div className={styles.main}>
                <Card className={styles.card}>
                    <Card.Header>
                        <Title level={2} content={'Informations'} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div>
                            <div className="spaceBetweenContainer">
                                <div className="mediumContainer">
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                {i18n.t('label.name')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanName}
                                        </Form.Text>
                                    </div>
                                    <div className={[styles.badgeContainer, "flexWrapStart"].join(' ')}>
                                        <Form.Label className={styles.statuslabel}>
                                            <strong>
                                                {i18n.t('label.status')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text className={styles.formText}>
                                            <StatusTag
                                                status={FhirStatus[testPlanStatus as keyof typeof FhirStatus]}
                                                statusMessage={testPlanStatus}
                                            />
                                        </Form.Text>
                                    </div>
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                {i18n.t('label.client')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {clientLabel}
                                        </Form.Text>
                                    </div>
                                    <div className={styles.formLabel}>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                {i18n.t('label.generaldescription')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanDescription}
                                        </Form.Text>
                                    </div>
                                </div>
                                <div className="mediumContainer">
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                ID :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanId}
                                        </Form.Text>
                                    </div>
                                    <div className={[styles.badgeContainer, "flexWrapStart"].join(' ')}>
                                        <Form.Label className={styles.statuslabel}>
                                            <strong>
                                                {i18n.t('label.result')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text className={styles.formText}>
                                            <StatusTag
                                                status={FhirStatus[testPlanResult as keyof typeof FhirStatus]}
                                                statusMessage={testPlanResult}
                                            />
                                        </Form.Text>
                                    </div>
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                {i18n.t('label.server')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {serverLabel}
                                        </Form.Text>
                                    </div>
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                Version :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanVersion}
                                        </Form.Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div className="section">
                    <BootstrapTable
                        keyField="name"
                        data={data}
                        columns={columns}
                        pagination={paginationFactory({ paginationSize: 20 })} />
                </div>
            </div>
        </PandoraPage>
    );
};

export default TestPlanReport;
