import { FunctionComponent } from "react";
import { Title } from "@fyrstain/fhir-front-library";
import styles from "./Home.module.css";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import i18n from "i18next";


const Home: FunctionComponent = () => {
  return (
    <PandoraPage loading={false} fitFooter={true} needsLogin={false}>
      <div className={styles.mainHomeContainer}>
        <img
          className={styles.homeimageIcon}
          alt="Home image"
          src="https://fyrstain.com/wp-content/uploads/2022/10/anim_une.svg"
        />
        <Title level={1} prefix={'Pandora'} content={i18n.t('title.testplatform')} />
      </div>
    </PandoraPage>
  );
};

export default Home;
