// React
import React, { useCallback, useEffect, useState } from 'react';
// FontAwesome
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Components
import TestMessageEdition from '../TestMessageEdition/TestMessageEdition';
import TestOperationStatusSelector from '../TestOperationStatusSelector/TestOperationStatusSelector';
// Styles
import styles from './AddTestOperation.module.css';
// FHIR
import { TestReport } from 'fhir/r5';
// Client
import Client from 'fhir-kit-client';
// Navigation
import { useNavigate } from 'react-router-dom';
// Translation
import i18n from 'i18next';

/////////////////////////////
//          Props          //
/////////////////////////////

interface AddTestOperationProps {
    // If the operation is an assert or an operation
    operationType: 'operation' | 'assert';
    // Choose between providing the TestReport resource or its ID
    testReport?: TestReport;
    testReportId?: string;
    // The step of the test
    testStep: string;
    // Callbacks to update the parent component for the addition of the operation
    onAddOperation?: (operation: any) => void;
    // Callbacks to update the parent component for the addition of the assert
    onAddAssert?: (assert: any) => void;
    // If the test report has operations
    hasOperations: boolean;
    // The id of the operation
    operationId?: string;
    // The id of the test
    testId?: string;
    // If the test report has no operation
    setAddHeaderAndRow?: (fn: () => void) => void;
    // Callback to get the latest changes made in the testReport 
    onUpdateTestReport?: (updatedTestReport: TestReport) => void;
    // The operations that already exist in the testReport
    existingOperationIds?: { setup: string[]; test: string[]; teardown: string[] };
    // Function to set the isCreatingTest state in the parent component
    setIsCreatingTest?: (isCreating: boolean) => void;
}

const AddTestOperation: React.FC<AddTestOperationProps> = (configs) => {

    /////////////////////////////
    //          States         //
    /////////////////////////////

    const [rows, setRows] = useState<Array<{ id: string, status: string, message: string }>>([]);
    const [testReport, setTestReport] = useState<any>(null);
    const [showHeaderAndRow, setShowHeaderAndRow] = useState(false);
    const [showAddButton, setShowAddButton] = useState(true);

    /////////////////////////////
    //         Constants       //
    /////////////////////////////

    const patchDocument: any[] = [];
    const patchData: { [key: string]: { result?: string; message?: string } } = {};

    /////////////////////////////
    //          Client         //
    /////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////
    //        Functions        //
    /////////////////////////////

    const navigate = useNavigate();

    /** 
     * Redirects to the Error page
     * 
     */
    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * Get existing IDs for a specific test step
     * 
     * @param testStep 
     * @param existingIds 
     * @param operationId 
     * @returns {string[]}
     */
    const getIdsForStep = (testStep: string, existingIds: any, operationId?: string): string[] => {
        switch (testStep) {
            case 'setupOperation':
                return existingIds.setup;
            case 'teardownOperation':
                return existingIds.teardown;
            case 'testOperation':
                return existingIds.test;
            case 'setupAssert':
                return existingIds.setup.filter((id: any) => id.includes('-ASS-') && id.startsWith(operationId || ''));
            case 'testAssert':
                return existingIds.test.filter((id: any) => id.includes('-ASS-') && id.startsWith(operationId || ''));
            default:
                throw new Error(`Invalid testStep: ${testStep}`);
        }
    };

    /**
     * Generate an ID for the different test steps
     * 
     * @param testStep 
     * @param operationId 
     * @returns {string}
     */
    const generateOperationId = (testStep: string, operationId?: string): string => {
        const existingIds = getExistingOperationIds(configs.existingOperationIds)
        const idsForStep = getIdsForStep(testStep, existingIds, operationId);
        const existingIndexes = idsForStep.map((id: any) => {
            const match = id.match(/\d+/g);
            return match ? parseInt(match[match.length - 1], 10) : 0;
        });
        const maxIndex = existingIndexes.length > 0 ? Math.max(...existingIndexes) : 0;
        const newIndex = maxIndex + 1;
        if (testStep === 'setupAssert' || testStep === 'testAssert') {
            return `${operationId}-ASS-${String(newIndex).padStart(2, '0')}`;
        }
        switch (testStep) {
            case 'setupOperation':
                return `SET-${String(newIndex).padStart(2, '0')}`;
            case 'teardownOperation':
                return `TED-${String(newIndex).padStart(2, '0')}`;
            case 'testOperation':
                return `TES-${String(newIndex).padStart(2, '0')}`;
            default:
                throw new Error(`Invalid testStep: ${testStep}`);
        }
    };

    /**
     * Get the existing operation ids
     * 
     */
    const getExistingOperationIds = (existingOperationIds?: { setup: string[]; teardown: string[]; test: string[] }) => {
        if (existingOperationIds) {
            return existingOperationIds;
        }
        if (!testReport) {
            return { setup: [], teardown: [], test: [] };
        }
        const { setup, teardown, test } = testReport;
        const setupIds = setup?.action?.flatMap((action: any) => {
            const id = action[configs.operationType]?.id;
            return id ? [id] : [];
        }) || [];
        const teardownIds = teardown?.action?.flatMap((action: any) => {
            const id = action[configs.operationType]?.id;
            return id ? [id] : [];
        }) || [];
        const testOperationIds = test?.flatMap((testItem: any) => {
            return testItem.action?.flatMap((action: any) => {
                const id = action[configs.operationType]?.id;
                return id ? [id] : [];
            }) || [];
        }) || [];
        return { setup: setupIds, teardown: teardownIds, test: testOperationIds };
    };

    /** 
      * Create a patch path and value
      * 
      * @param testStep
      * @param testReport
      * @param newOperation
      * @param testId
      */
    const createPatchPathAndValue = (testStep: string, testReport: any, newOperation: any, testId?: string) => {
        let patchPath;
        let patchValue;
        switch (testStep) {
            case 'setupOperation':
            case 'setupAssert':
                if (!testReport.setup) {
                    patchPath = '/setup';
                    patchValue = { action: [newOperation] };
                } else {
                    patchPath = '/setup/action/-';
                    patchValue = newOperation;
                }
                break;
            case 'teardownOperation':
                if (!testReport.teardown) {
                    patchPath = '/teardown';
                    patchValue = { action: [newOperation] };
                } else {
                    patchPath = '/teardown/action/-';
                    patchValue = newOperation;
                }
                break;
            case 'testOperation':
            case 'testAssert':
                if (!testReport.test) {
                    patchPath = '/test';
                    patchValue = [{ id: testId, action: [newOperation] }];
                } else {
                    let testIndex = testReport.test.findIndex((test: any) => test.id === testId);
                    if (testIndex === -1) {
                        patchPath = '/test/-';
                        patchValue = { id: testId, action: [newOperation] };
                    } else {
                        patchPath = `/test/${testIndex}/action/-`;
                        patchValue = newOperation;
                    }
                }
                break;
            default:
                throw new Error(`Invalid testStep: ${testStep}`);
        }
        return { patchPath, patchValue };
    };

    /**
     * Create a patch document
     * 
     * @param testReport 
     * @param operationType 
     * @param id 
     * @param result 
     * @param message 
     * @param index 
     */
    const createPatchDocument = (testReport: any, operationType: string, id: string, result: string, message: string, index?: number) => {
        const newOperation = {
            [operationType]: {
                id,
                result: result || '',
                message: message || '',
            },
        };
        const { patchPath, patchValue } = createPatchPathAndValue(configs.testStep, testReport, newOperation, configs.testId);
        const existingPatchIndex = patchDocument.findIndex(patch => patch.value[operationType]?.id === id);
        if (existingPatchIndex !== -1) {
            return;
        }
        if (index !== undefined) {
            const fullPath = patchPath.replace('-', index.toString())
            patchDocument.push({
                op: 'add',
                path: fullPath,
                value: patchValue,
            });
        } else {
            patchDocument.push({
                op: 'add',
                path: patchPath,
                value: patchValue,
            });
        }
        return patchDocument;
    };

    /**
     * Patch a resource
     * 
     * @param patchDocument
     */
    const patchResource = async (patchDocument: any) => {
        const testReportId = configs.testReportId || (configs.testReport && configs.testReport.id);
        try {
            const updatedTestReport = await fhirClient.patch({
                resourceType: 'TestReport',
                id: testReportId ?? '',
                JSONPatch: patchDocument,
            });
            return updatedTestReport;
        } catch (error) {
            onError();
            return null;
        }
    };

    /**
     * Fetch the test report
     * 
     * @param testReportId 
     * @param testReport 
     */
    const getTestReport = async (testReportId: string | undefined, testReport: TestReport | undefined) => {
        try {
            const id = testReportId || testReport?.id;
            if (!id) return;
            const response = await fhirClient.read({
                resourceType: 'TestReport',
                id: id,
            });
            setTestReport(response);
        } catch (error) {
            onError();
        }
    };

    /**
     * Insert an assert with their corresponding operation
     * 
     * @param actions 
     * @param testStep 
     * @param newAssert 
     * @param result 
     * @param message 
     */
    const insertAssert = (actions: any, testStep: string, newAssert: any, result: string, message: string) => {
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            if (action.operation && action.operation.id === testStep.split('-ASS-')[0]) {
                let insertIndex = i + 1;
                while (insertIndex < actions.length && actions[insertIndex] && actions[insertIndex].assert && actions[insertIndex].assert.id.startsWith(testStep.split('-ASS-')[0])) {
                    insertIndex++;
                }
                actions.splice(insertIndex, 0, newAssert);
                createPatchDocument(testReport, configs.operationType, testStep, result || '', message || '', insertIndex);
                break;
            }
        }
    };

    /**
     * Apply the patch document
     * 
     */
    const applyPatchDocument = async () => {
        for (const row of rows) {
            if (!patchData[row.id] || !patchData[row.id].result || !patchData[row.id].message) {
                alert(i18n.t('infomessage.completefield'))
                return;
            }
        }
        for (const [testStep, data] of Object.entries(patchData)) {
            const { result, message } = data;
            if (configs.operationType === 'assert') {
                let actions = [];
                switch (configs.testStep) {
                    case 'setupAssert':
                        actions = testReport.setup?.action || [];
                        break;
                    case 'testAssert':
                        const testIndex = testReport.test?.findIndex((test: any) => test.id === configs.testId);
                        if (testIndex !== -1) {
                            actions = testReport.test[testIndex].action || [];
                        }
                        break;
                }
                insertAssert(actions, testStep, { assert: { id: testStep, result, message } }, result || '', message || '');
            } else {
                createPatchDocument(testReport, configs.operationType, testStep, result || '', message || '');
            }
        }
        if (patchDocument.length > 0) {
            try {
                const updatedTestReport = await patchResource(patchDocument) as TestReport;
                if (updatedTestReport) {
                    setTestReport(updatedTestReport);
                    if (configs.onUpdateTestReport) {
                        configs.onUpdateTestReport(updatedTestReport);
                    }
                    setRows([]);
                    if (configs.operationType === 'operation' && configs.onAddOperation) {
                        configs.onAddOperation(updatedTestReport);
                    } else if (configs.operationType === 'assert' && configs.onAddAssert) {
                        configs.onAddAssert(updatedTestReport);
                    }
                    if (configs.setIsCreatingTest) {
                        configs.setIsCreatingTest(false);
                    }
                }
            } catch (error) {
                onError();
            }
        }
        setShowAddButton(true);
    };

    /**
    * Add a row
    * 
    * @param testStep 
    * @param operationId 
    */
    const addRow = (testStep: string, operationId?: string) => {
        const existingIds = getExistingOperationIds(configs.existingOperationIds);
        const idsForStep = getIdsForStep(testStep, existingIds, operationId);
        let newId = generateOperationId(testStep, operationId);
        while (idsForStep.includes(newId)) {
            newId = generateOperationId(testStep, operationId);
        }
        const newRow = { id: newId, status: '', message: '' };
        setRows(prevRows => {
            return [...prevRows, newRow];
        });
        if (configs.operationType === 'operation' && configs.onAddOperation) {
            configs.onAddOperation(newRow);
        } else if (configs.operationType === 'assert' && configs.onAddAssert) {
            configs.onAddAssert(newRow);
        }
    };

    /**
     * Add the header and a row
     * 
     */
    const addHeaderAndRow = () => {
        const operationId = configs.operationId || '';
        addRow(configs.testStep, operationId);
        setShowHeaderAndRow(true);
        setShowAddButton(false);
    };

    /////////////////////////////
    //         Callbacks       //
    /////////////////////////////

    /**
     * Handle the patch fallback message
     * 
     * @param id 
     * @param message 
     */
    const handlePatchFallbackMessage = (id: string, message: string) => {
        if (!patchData[id]) {
            patchData[id] = {};
        }
        patchData[id].message = message;
    }

    /**
     * Handle the patch fallback status
     * 
     * @param id 
     * @param status 
     */
    const handlePatchFallbackStatus = (id: string, status: string) => {
        if (!patchData[id]) {
            patchData[id] = {};
        }
        patchData[id].result = status;
    };

    /////////////////////////////
    //         LifeCycle       //
    /////////////////////////////

    /**
     * Get the test report
     */
    useEffect(() => {
        getTestReport(configs.testReportId, configs.testReport);
    }, [configs.testReportId, configs.testReport]);

    /**
     * Set the addHeaderAndRow function
     */
    useEffect(() => {
        if (configs.setAddHeaderAndRow) {
            configs.setAddHeaderAndRow(addHeaderAndRow);
        }
    }, [configs.setAddHeaderAndRow]);

    /////////////////////////////
    //         Content         //
    /////////////////////////////

    return (
        <div
            className={configs.operationType === 'operation' ? "container col-md-12 panel panel-default panel-body" : ""}
        >
            {(!configs.hasOperations && showHeaderAndRow) && (
                <table className="table table-condensed table-striped">
                    <thead>
                        <tr>
                            <th className={styles.columnsWidthOperation}>
                                {i18n.t('label.name')}
                            </th>
                            <th className={styles.smallColumnsWidthOperation}>
                                {i18n.t('label.result')}
                            </th>
                            <th className={styles.largeColumnsWidthOperation}>
                                Messages
                            </th>
                            <th className={styles.smallColumnsWidthOperation}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td className={styles.columnsWidthOperation}>{row.id}</td>
                                <td className={styles.smallColumnsWidthOperation}>
                                    <TestOperationStatusSelector
                                        testReportId={configs.testReportId || configs.testReport?.id}
                                        testReport={configs.testReport}
                                        testStep={row.id}
                                        updateType="result"
                                        onPatchFallback={handlePatchFallbackStatus}
                                    />
                                </td>
                                <td className={styles.largeColumnsWidthOperation}>
                                    <TestMessageEdition
                                        testReportId={configs.testReportId || configs.testReport?.id}
                                        testReport={configs.testReport}
                                        testStep={row.id}
                                        onPatchFallback={handlePatchFallbackMessage}
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        className="actionIcon"
                                        icon={faCheck}
                                        onClick={applyPatchDocument}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <table className=" table table-condensed table-striped">
                <tbody>
                    {configs.hasOperations && (
                        rows.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    className={configs.operationType === 'operation' ? styles.operationRow : ''}
                                >
                                    <td className={styles.columnsWidthOperation}>
                                        {row.id}
                                    </td>
                                    <td className={styles.smallColumnsWidthOperation}>
                                        <TestOperationStatusSelector
                                            testReportId={configs.testReportId || configs.testReport?.id}
                                            testReport={configs.testReport}
                                            testStep={row.id}
                                            updateType="result"
                                            onPatchFallback={handlePatchFallbackStatus}
                                        />
                                    </td>
                                    <td className={styles.largeColumnsWidthOperation}>
                                        <TestMessageEdition
                                            testReportId={configs.testReportId || configs.testReport?.id}
                                            testReport={configs.testReport}
                                            testStep={row.id}
                                            onPatchFallback={handlePatchFallbackMessage}
                                        />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="actionIcon"
                                            onClick={applyPatchDocument}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))
                    )}
                </tbody>
            </table>
            {showAddButton && configs.hasOperations && (
                <div className={styles.addOperationButtonContainer}>
                    <span
                        onClick={addHeaderAndRow}
                        className={styles.addOperationButton}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.addOperationIcon}
                        />
                        <strong>
                            {configs.operationType === 'operation' ? i18n.t('general.addoperation') : i18n.t('general.addassert')}
                        </strong>
                    </span>
                </div>
            )
            }
        </div >
    );
};

export default AddTestOperation;