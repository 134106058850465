import { FhirStatus, StatusAlert, StatusIcon, Title } from "@fyrstain/fhir-front-library";
import Client from "fhir-kit-client";
import { Bundle, BundleEntry, Composition, OperationOutcome, OperationOutcomeIssue, Parameters } from "fhir/r5";
import i18n from "i18next";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate, useParams } from "react-router-dom";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import styles from "./ValidationReport.module.css";

const ValidationReport: FunctionComponent = () => {

  const { validationReportId } = useParams();
  const [validationReport, setValidationReport] = useState({} as Bundle);
  const [issues, setIssues] = useState([] as OperationOutcomeIssue[]);
  const [loading, setLoading] = useState(false);
  const [testReport, setTestReport] = useState<{ id: string, name: string } | null>(null);

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
  });

  useEffect(() => {
    loadValidationReport();
  }, []);

  /**
    * Get test report to populate the field.
    * 
    * @param bundle    the bundle to the validation report.
    */
  async function getTestReport(bundle: Bundle) {
    const validationReportId = bundle.id || '';
    const response = await fhirClient.search({
      resourceType: 'TestReport',
      searchParams: {
        _elements: '_id,name',
        _filter: 'report-test-link sw "Bundle/' + validationReportId + '" or report-setup-link sw "Bundle/' + validationReportId + '"'
      }
    });
    if (response.entry && response.entry.length > 0) {
      const testReport = response.entry.find((entry: BundleEntry) => entry.resource?.resourceType === 'TestReport');
      if (testReport && testReport.resource) {
        return {
          id: testReport.resource.id,
          name: testReport.resource.name
        };
      }
    }
    throw new Error('Ce rapport d\'évaluation n\'est pas issu d\'un test');
  }

  /**
   * Load Validation report from the back to populate the textarea.
   * 
   * @returns the promise of a Validation report.
   */
  async function loadValidationReport() {
    setLoading(true);
    try {
      const response = await fhirClient.read({
        resourceType: 'Bundle',
        id: validationReportId ?? ''
      })
      const validationReport: Bundle = response as Bundle;
      if (validationReport.entry) {
        const type = (validationReport.entry[0].resource as Composition).type.coding?.filter(c => c.system === "http://isis.com/ValueSet/VS-CompositionSectionCode").map(c => c.code)[0] ?? "";
        console.log(type);
        if (type === "evaluation-report") {
          onEvaluationReport(validationReport.id ?? "");
        }
      }
      setValidationReport(validationReport);
      setIssues(getIssues(validationReport));
      try {
        const report = await getTestReport(validationReport);
        setTestReport(report);
      } catch (error) {
        setTestReport(null);
      }
    } catch (error) {
      onError();
    } finally {
      setLoading(false);
    }
  }

  const navigate = useNavigate();

  const onEvaluationReport = useCallback((id: string) => {
    navigate("/EvaluationReport/" + id);
  }, [navigate]);

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  /**
    * Get profile URL to populate the field.
    * 
    * @param validationReport    the bundle to the Validation report.
    */
  function getProfileUrl(validationReport: Bundle): string {
    let profileURL = 'N/A';
    validationReport.entry?.forEach(entry => {
      if ('Parameters' === entry.resource?.resourceType) {
        const parameters: Parameters = entry.resource as Parameters;
        const profileParam = parameters.parameter?.find(p => p.name === "profile");
        profileURL = profileParam?.valueCanonical
          ? profileParam?.valueCanonical
          : profileParam?.valueUrl ? profileParam?.valueUrl : "N/A";
      }
    })
    return profileURL;
  }

  /**
* Get issues to populate the notification.
* 
* @param validationReport    the bundle to the Validation report.
*/
  function getIssues(validationReport: Bundle) {
    const result: OperationOutcomeIssue[] = [];
    validationReport.entry?.forEach(entry => {
      if ('OperationOutcome' === entry.resource?.resourceType) {
        const operationOutcome: OperationOutcome = entry.resource as unknown as OperationOutcome;
        operationOutcome.issue?.forEach(issue => {
          result.push(issue);
        });
      }
    })
    return result.slice(1);
  }

  /**
* Count the percentage of the differents status of the issues to populate the progressBar.
* 
* @param severityStatus    the severity status to the Validation report bundle.
*/
  function countPercentageStatusIssues(severityStatus: string): number {
    const severity = issues.map(s => s.severity);
    return severity.filter(s => s === severityStatus).length * 100 / severity.length;
  }

  /**
* Count the number of the differents status of the issues to populate the progressBar.
* 
* @param severityStatus    the severity status to the Validation report bundle.
*/
  function countStatusIssues(severityStatus: string): number {
    return issues.map(s => s.severity).filter(s => s === severityStatus).length;
  }

  /**
* Get Validation Reponse to populate the status of the information notification.
* 
* @param validationReport    the bundle to the Validation report.
*/
  function getValidationReponse(validationReport: Bundle) {
    let validationStatus = 'N/A';
    validationReport.entry?.forEach(entry => {
      if ('OperationOutcome' === entry.resource?.resourceType) {
        const operationOutcome: OperationOutcome = entry.resource as unknown as OperationOutcome;
        const code = operationOutcome.issue
          ?.find(issue => issue.details?.coding?.find(coding => coding.code && (coding.system === "http://isis.com/test/CodeSystem/COS-ValidateStatus" || coding.system === "http://hl7.org/fhir/ValueSet/verificationresult-validation-status")))
          ?.details?.coding?.find(coding => coding.code && (coding.system === "http://isis.com/test/CodeSystem/COS-ValidateStatus" || coding.system === "http://hl7.org/fhir/ValueSet/verificationresult-validation-status"))?.code;
        validationStatus = code ? code : validationStatus;
      }
    })
    return validationStatus;
  }

  /**
  * Get raw content to populate the section.
  * 
  * @param validationReport    the bundle to the Validation report.
  */
  function getRawContent(validationReport: Bundle): string {
    let rawContent = 'N/A';
    validationReport.entry?.forEach(entry => {
      if ('Parameters' === entry.resource?.resourceType) {
        const parameters: Parameters = entry.resource as Parameters;
        const valueString = parameters.parameter?.find(p => p.name === "resource")?.valueString;
        rawContent = valueString ? valueString : 'N/A';
      }
    })
    return rawContent;
  }

  /**
   * Capitalize the first letter 
   * 
   * @param string the string to capitalize
   */
  function capitalizeFirst(string: string): string {
    const splitted = string.split('');
    splitted[0] = splitted[0].toUpperCase();
    return splitted.join('');
  }

  return (
    <PandoraPage titleKey='title.validationreport' loading={loading} needsLogin={false} >
      <>
        <Card>
          <Card.Header>
            <Title level={2} content={'Informations'} />
          </Card.Header>
          <Card.Body className="cardBody">
            <div className={styles.alertContainer}>
              <StatusAlert
                status={FhirStatus[getValidationReponse(validationReport) as keyof typeof FhirStatus]}
              >
                <div className={styles.alertContainer}>
                  <div>
                    <StatusIcon
                      status={FhirStatus[getValidationReponse(validationReport) as keyof typeof FhirStatus]}
                    />
                    <strong className={styles.alertTitle}>
                      {`${capitalizeFirst(getValidationReponse(validationReport))} validation`}
                    </strong>
                  </div>
                </div>
              </StatusAlert>
            </div>
            <div className={styles.form}>
              <div className={styles.formTextLabel}>
                <Form.Label>
                  <strong className={styles.label}>
                    ID :
                  </strong>
                </Form.Label>
                <Form.Text >
                  {validationReport.id ? validationReport.id : 'N/A'}
                </Form.Text>
              </div>
              <div className={styles.formTextLabel}>
                <Form.Label>
                  <strong className={styles.label}>
                    {i18n.t('label.profileurl')} :
                  </strong>
                </Form.Label>
                <Form.Text >
                  {getProfileUrl(validationReport)}
                </Form.Text>
              </div>
              <div className={styles.formTextLabel}>
                <Form.Label>
                  <strong className={styles.label}>
                    Date :
                  </strong>
                </Form.Label>
                <Form.Text >
                  {validationReport.timestamp ? validationReport.timestamp : 'N/A'}
                </Form.Text>
              </div>
              <div className={styles.formTextLabel}>
                <Form.Label>
                  <strong className={styles.label}>
                    Test ?
                  </strong>
                </Form.Label>
                <Form.Text className={testReport ? '' : 'text-danger'}>
                  {testReport ? `${testReport.id} / ${testReport.name}` : i18n.t('errormessage.notlinkedvalidation')}
                </Form.Text>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className={styles.issues}>
          <Card.Header>
            <Title level={2} content={i18n.t("title.issues")} />
          </Card.Header>
          <Card.Body>
            <div className={styles.formTextLabel}>
              <div className={styles.summaryStatusContainer}>
                <Form.Label>
                  <strong className={styles.summaryStatusLabel}>
                    {i18n.t("label.statussummary")} :
                  </strong>
                </Form.Label>
                <div className={styles.progressBarContainer}>
                  <Form.Text>
                    <ProgressBar>
                      <ProgressBar variant="warning" now={countPercentageStatusIssues('warning')} label={countStatusIssues('warning')} />
                      <ProgressBar variant="danger" now={countPercentageStatusIssues('error')} label={countStatusIssues('error')} />
                      <ProgressBar variant="info" now={countPercentageStatusIssues('information')} label={countStatusIssues('information')} />
                      <ProgressBar variant="dark" now={countPercentageStatusIssues('fatal')} label={countStatusIssues('fatal')} />
                    </ProgressBar>
                  </Form.Text>
                </div>
              </div>
            </div>
            {issues.map(issue =>
              <div className={styles.alert}>
                <StatusAlert
                  status={FhirStatus[issue.severity as keyof typeof FhirStatus]}
                >
                  <div className={styles.alertContainer}>
                    <div>
                      <StatusIcon
                        status={FhirStatus[issue.severity as keyof typeof FhirStatus]}
                      />
                      <strong className={styles.alertTitle}>
                        {capitalizeFirst(issue.severity)}
                      </strong>
                    </div>
                    <div className={styles.notificationContent}>
                      <div className={styles.labelsNotification}>
                        <div className={styles.formTextLabel}>
                          <Form.Label>
                            <strong className={styles.label}>
                              Code :
                            </strong>
                          </Form.Label>
                          <Form.Text >
                            {issue.code ? issue.code : 'N/A'}
                          </Form.Text>
                        </div>
                        <div className={styles.formTextLabel}>
                          <Form.Label>
                            <strong className={styles.label}>
                              Diagnostics :
                            </strong>
                          </Form.Label>
                          <Form.Text >
                            {issue.diagnostics ? issue.diagnostics : 'N/A'}
                          </Form.Text>
                        </div>
                        {issue.details?.text !== undefined &&
                          <div className={styles.formTextLabel}>
                            <Form.Label>
                              <strong className={styles.label}>
                                Details :
                              </strong>
                            </Form.Label>
                            <Form.Text >
                              {issue.details?.text ? issue.details?.text : 'N/A'}
                            </Form.Text>
                          </div>
                        }
                        {issue.location !== undefined &&
                          <div className={styles.formTextLabel}>
                            <Form.Label>
                              <strong className={styles.label}>
                                Expression :
                              </strong>
                            </Form.Label>
                            <Form.Text >
                              {issue.location[0] ? issue.location[0] : 'N/A'}
                            </Form.Text>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </StatusAlert>
              </div>
            )}
          </Card.Body>
        </Card>
        <Card className={styles.issues}>
          <Card.Header>
            <Title level={2} content={i18n.t("title.rawcontent")} />
          </Card.Header>
          <Card.Body>
            <textarea className="form-control" rows={10} readOnly value={getRawContent(validationReport)} />
          </Card.Body>
        </Card>
      </>
    </PandoraPage>
  );
};

export default ValidationReport;