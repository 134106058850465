// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testScriptViewer_formLabel__DYVOM {
    margin: 0 1rem 1rem 0;
}

.testScriptViewer_formText__QKPFp {
    margin-bottom: 1rem;
}

.testScriptViewer_statusTag__ZBj2N {
    margin-bottom: 0.5rem;
}

.testScriptViewer_title3Container__Dgp5e {
    margin: 0 0 1rem 1rem;
}

.testScriptViewer_operationCardContainer__3pLNA {
    background-color: var(--secondary-color);
    border-radius: 0.5rem;
    color: var(--white);
    min-height: 20rem;
}

.testScriptViewer_operationCard__IYqnJ,
.testScriptViewer_assertCard__ds641 {
    width: 18rem;
    margin: 0 0 1rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.testScriptViewer_operationCard__IYqnJ {
    height: 20rem;
}

.testScriptViewer_assertCardContainer__yAWYS {
    border-radius: 0.5rem;
    min-height: 18rem;
}

.testScriptViewer_whiteText__3s4SH {
    color: var(--white);
}

.testScriptViewer_operationButton__ThuCW {
    margin: 1rem 0 0 1rem;
    width: 18rem;
}

.testScriptViewer_assertButton__5GetA {
    width: 16rem;
    margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testScriptViewer/testScriptViewer.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,wCAAwC;IACxC,qBAAqB;IACrB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;;IAEI,YAAY;IACZ,qBAAqB;IACrB,qBAAqB;IACrB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".formLabel {\r\n    margin: 0 1rem 1rem 0;\r\n}\r\n\r\n.formText {\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n.statusTag {\r\n    margin-bottom: 0.5rem;\r\n}\r\n\r\n.title3Container {\r\n    margin: 0 0 1rem 1rem;\r\n}\r\n\r\n.operationCardContainer {\r\n    background-color: var(--secondary-color);\r\n    border-radius: 0.5rem;\r\n    color: var(--white);\r\n    min-height: 20rem;\r\n}\r\n\r\n.operationCard,\r\n.assertCard {\r\n    width: 18rem;\r\n    margin: 0 0 1rem 1rem;\r\n    border-radius: 0.5rem;\r\n    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.operationCard {\r\n    height: 20rem;\r\n}\r\n\r\n.assertCardContainer {\r\n    border-radius: 0.5rem;\r\n    min-height: 18rem;\r\n}\r\n\r\n.whiteText {\r\n    color: var(--white);\r\n}\r\n\r\n.operationButton {\r\n    margin: 1rem 0 0 1rem;\r\n    width: 18rem;\r\n}\r\n\r\n.assertButton {\r\n    width: 16rem;\r\n    margin-top: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLabel": `testScriptViewer_formLabel__DYVOM`,
	"formText": `testScriptViewer_formText__QKPFp`,
	"statusTag": `testScriptViewer_statusTag__ZBj2N`,
	"title3Container": `testScriptViewer_title3Container__Dgp5e`,
	"operationCardContainer": `testScriptViewer_operationCardContainer__3pLNA`,
	"operationCard": `testScriptViewer_operationCard__IYqnJ`,
	"assertCard": `testScriptViewer_assertCard__ds641`,
	"assertCardContainer": `testScriptViewer_assertCardContainer__yAWYS`,
	"whiteText": `testScriptViewer_whiteText__3s4SH`,
	"operationButton": `testScriptViewer_operationButton__ThuCW`,
	"assertButton": `testScriptViewer_assertButton__5GetA`
};
export default ___CSS_LOADER_EXPORT___;
