// Font awesome
import { faCircleInfo, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Client
import Client from 'fhir-kit-client';
// FHIR
import { Bundle, Device, Parameters, TestReport, TestScript } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// React Bootstrap
import { Button, Card, Form, Table } from "react-bootstrap";
// React Router
import { useNavigate, useParams } from "react-router-dom";
// Component
import PandoraPage from '../../components/PandoraPage/PandoraPage';
import { Title } from "@fyrstain/fhir-front-library";
// Styles
import styles from "./systemsSelection.module.css";

const SystemsSelection: FunctionComponent = () => {

    /////////////////////////////////////
    //             Tooltip             //
    /////////////////////////////////////

    const clientTooltip = i18n.t('tooltip.clientdescription');
    const serverTooltip = i18n.t('tooltip.serverdescription');

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [deviceClient, setDeviceClient] = useState("")
    const [deviceServer, setDeviceServer] = useState("");
    const [loading, setLoading] = useState(false);
    const [isAutomatable, setIsAutomatable] = useState(false);
    const [isAutomated, setIsAutomated] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [clientActor, setClientActor] = useState("");
    const [serverActor, setServerActor] = useState("");

    /////////////////////////////////////
    //      Constants / ValueSet       //
    /////////////////////////////////////

    const { testScriptId } = useParams();
    const [testScriptName, setTestScriptName] = useState("");
    const [testScriptDescription, setTestScriptDescription] = useState("");
    const [devicesClient, setDevicesClient] = useState([] as Device[]);
    const [devicesServer, setDevicesServer] = useState([] as Device[]);

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    const navigate = useNavigate();

    const toReportDetails = useCallback((id: string) => {
        navigate("/TestReportDetails/" + id);
    }, [navigate]);

    async function launchTest() {
        startTestScript();
    }

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * Check if the TestScript is automatable based on its meta tags.
     * @param testScript The TestScript resource to check.
     * @returns True if automatable, false otherwise.
     */
    function isTestScriptAutomatable(testScript: TestScript): boolean {
        const automatableTag = testScript.meta?.tag?.find(tag => tag.system === "http://isis.com/test/CodeSystem/COS-AutomatableTest" && tag.code === "automatable");
        return automatableTag !== undefined;
    }

    /**
     * Load the TestScript informations to display in fields.
     */
    async function loadTestScriptInformations() {
        try {
            const response = await fhirClient.request('/TestScript/' + testScriptId, { method: 'GET' });
            const testScript: TestScript = response as TestScript;
            setTestScriptName(testScript.name);
            setTestScriptDescription(testScript.description ?? "undefined");
            setIsAutomatable(isTestScriptAutomatable(testScript));
            setClientActor(testScript.origin?.[0]?.profile?.code ?? 'N/A');
            setServerActor(testScript.destination?.[0]?.profile?.code ?? 'N/A');
        } catch (error) {
            console.log(error);
            onError();
        }
        return [];
    }

    /**
     * start the Test script to build the test.
     */
    async function startTestScript() {
        try {
            const bodyParameters = {
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name": "serverID",
                        "valueString": deviceServer
                    },
                    {
                        "name": "clientID",
                        "valueString": deviceClient
                    },
                    {
                        "name": "automatic",
                        "valueBoolean": isAutomated
                    }
                ]
            } as Parameters;
            const response = await fhirClient.operation({
                name: '$launchTest',
                resourceType: 'TestScript',
                id: testScriptId,
                method: 'POST',
                input: bodyParameters,
            });
            const report: TestReport = response as TestReport;
            toReportDetails(report.id ?? "");
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
     * Load the page used for select fields.
     */
    async function loadPage() {
        setLoading(true);
        await loadSystemData();
        await loadTestScriptInformations();
        setLoading(false);
    }

    /**
     * Load the Device name to display in select fields.
     */
    async function loadSystemData() {
        try {
            const response = await fhirClient.search({
                resourceType: 'Device',
                searchParams: {
                    "_elements": "displayName,id,type",
                    "type": "http://fyrstain.com/pdt/ValueSet/systemTypes|simulator,http://fyrstain.com/pdt/ValueSet/systemTypes|sut"
                }
            })
            const bundle: Bundle = response as Bundle;
            const devices = bundle.entry?.map(
                entry => entry.resource as Device
            ) ?? [];
            setDevicesClient(devices);
            setDevicesServer(devices);
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
     * Reset the page to its initial state.
     * 
     */
    function reset() {
        setLoading(true);
        setDeviceClient("undefined");
        setDeviceServer("undefined");
        setWarningMessage("");
        setLoading(false);
    }

    /**
     * Reset the row intended for the client
     */
    function resetClient() {
        setDeviceClient("undefined");
    }

    /**
    * Reset the row intended for the server
    */
    function resetServer() {
        setDeviceServer("undefined");
    }

    /////////////////////////////////////
    //            LifeCycle            //
    /////////////////////////////////////

    /**
     * Display the page when the information is loaded 
     */
    useEffect(() => {
        loadTestScriptInformations();
        loadPage();
    }, []);

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    /**
     * Check if the device is a simulator.
     * 
     * @param device 
     * 
     */
    function isSimulator(device: Device): boolean {
        return device.type?.some(type => type.coding?.some(coding => coding.code === "simulator")) ?? false;
    }

    /**
     * Handle the change of the client select field.
     * 
     * @param event 
     */
    function handleClientChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedDeviceId = event.target.value;
        const selectedDevice = devicesClient.find(device => device.id === selectedDeviceId);

        if (!isAutomatable && selectedDevice && isSimulator(selectedDevice)) {
            setWarningMessage(i18n.t('infomessage.simulator'));
        } else {
            setWarningMessage("");
        }
        setDeviceClient(selectedDeviceId);
    }

    /**
     * Get the option for the select field.
     * 
     * @param device
     * 
     * @returns The option for the select field.
     * 
     */
    function getOption(device: Device) {
        return <option value={device.id}>
            {device.displayName ?? ""} / {device.type?.map(type => type.coding?.map(coding => coding.display ?? ""))}
        </option>;
    }

    return (
        <PandoraPage titleKey='title.systemsselection' loading={loading} needsLogin={true} >
            <div className={styles.main}>
                <Card className={styles.card}>
                    <Card.Header>
                        <Title level={2} content={i18n.t('title.testinformations')} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div className={styles.form}>
                            <div className={styles.formTextLabel}>
                                <Form.Label className={styles.formLabel}>
                                    <strong className={styles.label}>
                                        ID :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testScriptId}
                                </Form.Text>
                            </div>
                            <div className={styles.formTextLabel}>
                                <Form.Label className={styles.formLabel}>
                                    <strong className={styles.label}>
                                        {i18n.t('label.name')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testScriptName}
                                </Form.Text>
                            </div>
                            <div className={styles.formTextLabel}>
                                <Form.Label className={styles.formLabel}>
                                    <strong>
                                        {i18n.t('label.generaldescription')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testScriptDescription}
                                </Form.Text>
                            </div>
                            <Form className={['flexWrapStart', styles.automatableSwitch].join(' ')}>
                                <Form.Check
                                    id="automatable"
                                    type="switch"
                                    disabled={isAutomatable || !isAutomatable}
                                    checked={isAutomatable}
                                    onChange={() => setIsAutomatable(!isAutomatable)}
                                />
                                <p>
                                    {i18n.t('switchbutton.automatable')}
                                </p>
                            </Form >
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Title level={2} content={i18n.t('title.launchtest')} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <Form className='flexWrapStart'>
                            <Form.Check
                                type="switch"
                                id="automated"
                                className={styles.formTextLabel}
                                disabled={!isAutomatable}
                                checked={isAutomated}
                                onChange={() => setIsAutomated(!isAutomated)}
                            />
                            <p>
                                {i18n.t('switchbutton.automated')}
                            </p>
                        </Form>
                        <div className={styles.systemsTable}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>{i18n.t('title.systems')}</th>
                                        <th>{i18n.t('label.actor')}</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="largeContainer spaceBetweenContainer select">
                                                <Form.Select className={styles.formSelect}
                                                    value={deviceClient}
                                                    onChange={handleClientChange}
                                                >
                                                    <option value="undefined">
                                                        {i18n.t('placeholder.client')}
                                                    </option>
                                                    {devicesClient.map(device => getOption(device))}
                                                </Form.Select>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btnInfo"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title={clientTooltip}
                                                >
                                                    <FontAwesomeIcon icon={faCircleInfo} />
                                                </button>
                                            </div>
                                        </td>
                                        <td>{clientActor}</td>
                                        <td>
                                            <FontAwesomeIcon
                                                icon={faRotate}
                                                className='actionIcon'
                                                onClick={resetClient}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="largeContainer spaceBetweenContainer select">
                                                <Form.Select className={styles.formSelect}
                                                    value={deviceServer}
                                                    onChange={(e) => {
                                                        setDeviceServer(e.target.value);
                                                    }}
                                                >
                                                    <option value="undefined">
                                                        {i18n.t('placeholder.server')}
                                                    </option>
                                                    {devicesServer.map(device => getOption(device))}
                                                </Form.Select>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btnInfo"
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title={serverTooltip}
                                                >
                                                    <FontAwesomeIcon icon={faCircleInfo} />
                                                </button>
                                            </div>
                                        </td>
                                        <td>{serverActor}</td>
                                        <td>
                                            <FontAwesomeIcon
                                                icon={faRotate}
                                                className='actionIcon'
                                                onClick={resetServer}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        {warningMessage && (
                            <div
                                className={["alert alert-warning", styles.warningAlert].join(' ')}
                                role="alert"
                            >
                                {warningMessage}
                            </div>
                        )}
                        <div className={styles.btnContainer}>
                            <Button
                                className="button"
                                variant="danger"
                                onClick={launchTest}
                            >
                                {i18n.t('button.launch')}
                            </Button>
                            <Button
                                className="button"
                                variant="secondary"
                                onClick={reset}
                            >
                                {i18n.t('button.reset')}
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </PandoraPage >
    );
};

export default SystemsSelection;