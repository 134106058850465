// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testPlanReport_badgeContainer__8fDex {
    width: 100%;
}

.testPlanReport_label__HAZ06 {
    margin: 0 1rem 1rem 0;
    line-height: 1.2rem;
}

.testPlanReport_statuslabel__TQEY9 {
    margin: 0.5rem 1rem 1rem 0;
    line-height: 1.2rem;
}

.testPlanReport_formLabel__FiFkO {
    text-align: justify;
}

.testPlanReport_formText__bkDd6 {
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testPlanReport/testPlanReport.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".badgeContainer {\r\n    width: 100%;\r\n}\r\n\r\n.label {\r\n    margin: 0 1rem 1rem 0;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.statuslabel {\r\n    margin: 0.5rem 1rem 1rem 0;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n    text-align: justify;\r\n}\r\n\r\n.formText {\r\n    margin-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeContainer": `testPlanReport_badgeContainer__8fDex`,
	"label": `testPlanReport_label__HAZ06`,
	"statuslabel": `testPlanReport_statuslabel__TQEY9`,
	"formLabel": `testPlanReport_formLabel__FiFkO`,
	"formText": `testPlanReport_formText__bkDd6`
};
export default ___CSS_LOADER_EXPORT___;
