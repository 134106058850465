// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddTestOperation_table__nPkLO {
    width: 100%;
    border-collapse: collapse;
}

.AddTestOperation_smallColumnsWidthOperation__tCPhl {
    width: 15.3%;
}

.AddTestOperation_columnsWidthOperation__lVHJE {
    width: 22%;
}

.AddTestOperation_largeColumnsWidthOperation__Dhrqm {
    width: 43.7%;
}

.AddTestOperation_operationRow__ZzbbN {
    border: var(--color-lightGray) solid 0.1rem;
}

.AddTestOperation_table__nPkLO th,
.AddTestOperation_table__nPkLO td {
    border: 1px solid #ddd;
    padding: 8px;
}

.AddTestOperation_addOperationButtonContainer__RqUG8 {
    margin: -1.5rem 0 1rem 0;

}

.AddTestOperation_addOperationButton__OP6l2 {
    cursor: pointer;
}

.AddTestOperation_addOperationIcon__yd6sf {
    margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/TestReport/AddTestOperation/AddTestOperation.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,wBAAwB;;AAE5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n}\r\n\r\n.smallColumnsWidthOperation {\r\n    width: 15.3%;\r\n}\r\n\r\n.columnsWidthOperation {\r\n    width: 22%;\r\n}\r\n\r\n.largeColumnsWidthOperation {\r\n    width: 43.7%;\r\n}\r\n\r\n.operationRow {\r\n    border: var(--color-lightGray) solid 0.1rem;\r\n}\r\n\r\n.table th,\r\n.table td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n}\r\n\r\n.addOperationButtonContainer {\r\n    margin: -1.5rem 0 1rem 0;\r\n\r\n}\r\n\r\n.addOperationButton {\r\n    cursor: pointer;\r\n}\r\n\r\n.addOperationIcon {\r\n    margin-right: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `AddTestOperation_table__nPkLO`,
	"smallColumnsWidthOperation": `AddTestOperation_smallColumnsWidthOperation__tCPhl`,
	"columnsWidthOperation": `AddTestOperation_columnsWidthOperation__lVHJE`,
	"largeColumnsWidthOperation": `AddTestOperation_largeColumnsWidthOperation__Dhrqm`,
	"operationRow": `AddTestOperation_operationRow__ZzbbN`,
	"addOperationButtonContainer": `AddTestOperation_addOperationButtonContainer__RqUG8`,
	"addOperationButton": `AddTestOperation_addOperationButton__OP6l2`,
	"addOperationIcon": `AddTestOperation_addOperationIcon__yd6sf`
};
export default ___CSS_LOADER_EXPORT___;
