// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validatorHome_validatorHome__5hfFR {
  width: 65%;
  margin: auto;
}

.validatorHome_formLabel__3Ecn\\+ {
  margin: 2rem 0 1rem 0;
  position: relative;
}

.validatorHome_buttonsContainer__8e20N {
  margin-bottom: 1rem 0 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/validatorHome/validatorHome.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".validatorHome {\r\n  width: 65%;\r\n  margin: auto;\r\n}\r\n\r\n.formLabel {\r\n  margin: 2rem 0 1rem 0;\r\n  position: relative;\r\n}\r\n\r\n.buttonsContainer {\r\n  margin-bottom: 1rem 0 2rem 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"validatorHome": `validatorHome_validatorHome__5hfFR`,
	"formLabel": `validatorHome_formLabel__3Ecn+`,
	"buttonsContainer": `validatorHome_buttonsContainer__8e20N`
};
export default ___CSS_LOADER_EXPORT___;
