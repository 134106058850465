// FHIR
import Client from "fhir-kit-client";
import { TestScript, TestScriptFixture, TestScriptTestAction } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useReducer, useState } from "react";
// React Bootstrap
import { Accordion, Button, Card, Form } from "react-bootstrap";
// React Router
import { useNavigate, useParams } from "react-router-dom";
// Components
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import { Title, StatusTag, FhirStatus } from "@fyrstain/fhir-front-library";
// CSS
import styles from "./testScriptViewer.module.css";

const TestScriptViewer: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [loading, setLoading] = useState(true);
    const [tests, setTests] = useState([] as { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[]);
    const [setups, setSetups] = useState([] as { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[]);
    const { testScriptId } = useParams();
    const [testScriptTitle, setTestScriptTitle] = useState("");
    const [testScriptDescription, setTestScriptDescription] = useState("");
    const [testScriptStatus, setTestScriptStatus] = useState("");
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [toggleSetupOperation, setToggleSetupOperation] = useState(true);
    const [toggleTestOperation, setToggleTestOperation] = useState(true);
    const [script, setScript] = useState({} as TestScript);
    const [showOperationButton, setShowOperationButton] = useState(false);
    const [fixtures, setFixtures] = useState([] as { toggle: boolean, fixture: TestScriptFixture }[]);
    const [closedFixture, setClosedFixture] = useState(false);

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Action              //
    /////////////////////////////////////

    const navigate = useNavigate();

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    useEffect(() => {
        loadTestScriptInformations();
    }, []);

    const lauchTest = useCallback((id: string) => {
        navigate("/SystemsSelection/" + id);
    }, [navigate]);

    /**
     * Navigate to the Fixture Edition page from the Document Reference type fixture id
     * 
     * @param fixtureId the id of the fixture.
     * @returns the promise of a DocumentReference.
     * 
     */
    async function navigateToFixtureEdition(fixtureId: string) {
        try {
            const fixture = script.fixture?.find(f => f.id === fixtureId);
            if (fixture && fixture.resource && fixture.resource.reference) {
                const resourceId = fixture.resource.reference.split("/").pop();
                navigate("/FixtureEdition/" + resourceId);
            }
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
     * Load Test script informations from the back to populate the page.
     * 
     * @returns the promise of a TestScript.
     */
    async function loadTestScriptInformations() {
        setLoading(true);
        try {
            const response = await fhirClient.read({
                resourceType: "TestScript",
                id: testScriptId ?? '',
            });
            const testScript = response as TestScript;
            setTestScriptTitle(testScript.title ?? "");
            setTestScriptDescription(testScript.description ?? "");
            setTestScriptStatus(testScript.status ?? "");
            setSetups(retrieveSetups(testScript));
            setTests(retrieveTests(testScript));
            setScript(testScript);
            setFixtures(retrieveFixture(testScript));
            setLoading(false);
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
     * Retrieve setups operations and asserts to populate the fields.
     * 
     * @param script the setup to the Test script.
     */
    function retrieveSetups(script: TestScript) {
        const result: { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[] = [];
        script.setup?.action.filter(a => a.operation).forEach(a => {
            result.push({ "toggle": false, "operation": a, asserts: script.setup?.action.filter(action => action.assert?.id?.includes(a.operation?.id ?? '')) ?? [] })
        })
        return result;
    }

    /**
     * Retrieve tests operations and asserts tests to populate the fields.
     * 
     * @param script    the test to the Test script.
     */
    function retrieveTests(script: TestScript) {
        const result: { toggle: boolean, operation: TestScriptTestAction, asserts: TestScriptTestAction[] }[] = [];
        script.test?.forEach(test => {
            test.action.filter(a => a.operation).forEach(a => {
                result.push({ "toggle": false, "operation": a, asserts: test.action.filter(action => action.assert?.id?.includes(a.id ?? '')) ?? [] })
            })
        })
        return result;
    }

    /**
     * Retrieve fixtures to populate the fields.
     * 
     * @param script the test to the Test script.
     */
    function retrieveFixture(script: TestScript) {
        const result: { toggle: boolean, fixture: TestScriptFixture }[] = [];
        script.fixture?.forEach(f => {
            result.push({ "toggle": false, "fixture": f })
        })
        return result;
    }

    // Component to display the label value details
    const LabelValueDetail: FunctionComponent<{ label: string, value: string }> = ({ label, value }) => (
        <div className="d-flex gap-2">
            <Form.Label className="fw-semibold">{label}:</Form.Label>
            <Form.Text>{value}</Form.Text>
        </div>
    );

    /////////////////////////////////////
    //             Content             //
    /////////////////////////////////////

    return (
        <PandoraPage titleKey='title.testscriptviewer' loading={loading} needsLogin={true}>
            <>
                <div className="d-flex flex-column gap-4">
                    <Card>
                        <Card.Header>
                            <Title level={2} content="Informations" />
                        </Card.Header>
                        <Card.Body className="d-flex flex-column gap-0.5">
                            <LabelValueDetail
                                label="ID"
                                value={testScriptId ?? 'N/A'}
                            />
                            <LabelValueDetail
                                label={i18n.t('label.name')}
                                value={testScriptTitle ?? 'N/A'}
                            />
                            <div className="d-flex gap-2">
                                <Form.Label className="fw-bold">
                                    {i18n.t('label.resourcestatus')}:
                                </Form.Label>
                                <Form.Text>
                                    <StatusTag
                                        status={FhirStatus[testScriptStatus as keyof typeof FhirStatus]}
                                        statusMessage={testScriptStatus ?? 'N/A'}
                                    />
                                </Form.Text>
                            </div>
                            <LabelValueDetail
                                label={i18n.t('label.generaldescription')}
                                value={testScriptDescription ?? 'N/A'}
                            />
                        </Card.Body>
                    </Card>

                    <Accordion defaultActiveKey="none">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <Title level={2} content={'Setup'} />
                            </Accordion.Header>
                            <Accordion.Body className={styles.scrollable}>
                                <div className="row g-5">
                                    {setups.map(setup => (
                                        <>
                                            {setup.toggle ? (
                                                <div className="d-flex flex-column gap-3">
                                                    <Title level={3} content={`${setup.operation.operation?.id ?? 'N/A'}/${setup.operation.operation?.label ?? 'N/A'}`} />
                                                    <div className={`row g-3 ${styles.scrollable}`}>
                                                        {setup.asserts.map(assert =>
                                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                                <Card>
                                                                    <div className={styles.assertCardContainer}>
                                                                        <Card.Body>
                                                                            <Card.Title>
                                                                                {assert.assert?.id ?? 'N/A'}
                                                                            </Card.Title>
                                                                            <Card.Text>
                                                                                <LabelValueDetail
                                                                                    label={i18n.t('label.name')}
                                                                                    value={assert.assert?.label ?? 'N/A'}
                                                                                />
                                                                                <LabelValueDetail
                                                                                    label="Direction"
                                                                                    value={assert.assert?.direction ?? 'N/A'}
                                                                                />
                                                                                <div className={styles.description}>
                                                                                    <Form.Text>
                                                                                        {assert.assert?.description ?? 'N/A'}
                                                                                    </Form.Text>
                                                                                </div>
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {!showOperationButton &&
                                                        <div className="d-flex justify-content-center">
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    setup.toggle = !setup.toggle;
                                                                    forceUpdate();
                                                                    setToggleSetupOperation(true);
                                                                }}
                                                            >
                                                                {i18n.t('button.seeoperations')}
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            ) : (
                                                toggleSetupOperation && (
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <Card>
                                                            <div className={`${styles.operationCardContainer} d-flex flex-column flex-grow-1`}>
                                                                <Card.Body className="d-flex flex-column flex-grow-1 justify-content-between">
                                                                    <div>
                                                                        <Card.Title>
                                                                            {setup.operation.operation?.id ?? 'N/A'}
                                                                        </Card.Title>
                                                                        <Card.Text>
                                                                            <LabelValueDetail
                                                                                label={i18n.t('label.name')}
                                                                                value={setup.operation.operation?.label ?? 'N/A'}
                                                                            />
                                                                            <LabelValueDetail
                                                                                label={i18n.t('label.method')}
                                                                                value={setup.operation.operation?.method?.toLocaleUpperCase() ?? 'N/A'}
                                                                            />
                                                                            <div className="d-flex gap-2">
                                                                                <Form.Label className="fw-semibold">
                                                                                    {i18n.t('label.fixture')}:
                                                                                </Form.Label>
                                                                                <Form.Text>
                                                                                    <a
                                                                                        href="#fixture"
                                                                                        onClick={() => {
                                                                                            const updatedFixtures = fixtures.map(f => ({
                                                                                                ...f,
                                                                                                toggle: f.fixture.id === setup.operation.operation?.targetId || f.fixture.id === setup.operation.operation?.sourceId
                                                                                            }));
                                                                                            setFixtures(updatedFixtures);
                                                                                            setClosedFixture(false);
                                                                                        }}
                                                                                    >
                                                                                        {(setup.operation.operation?.targetId || setup.operation.operation?.sourceId) ?? 'N/A'}
                                                                                    </a>
                                                                                </Form.Text>
                                                                            </div>
                                                                            <div className={styles.description}>
                                                                                <Form.Text>
                                                                                    <p>{setup.operation.operation?.description ?? 'N/A'}</p>
                                                                                </Form.Text>
                                                                            </div>
                                                                        </Card.Text>
                                                                    </div>{setup.asserts.some(assert =>
                                                                        assert.assert?.id?.startsWith(setup.operation.operation?.id ?? '')
                                                                    ) && (
                                                                            <div className="d-flex justify-content-center mt-auto">
                                                                                <Button
                                                                                    variant="primary"
                                                                                    onClick={() => {
                                                                                        setup.toggle = !setup.toggle;
                                                                                        forceUpdate();
                                                                                        setToggleSetupOperation(false);
                                                                                        setClosedFixture(true);
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('button.seeasserts')}
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                </Card.Body>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ))}
                                </div>
                            </Accordion.Body >
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <Title level={2} content={'Test'} />
                            </Accordion.Header>
                            <Accordion.Body className={styles.scrollable}>
                                <div className="row g-5">
                                    {tests.map(test => (
                                        <>
                                            {test.toggle ? (
                                                // Display test details when toggle is active
                                                <div className="d-flex flex-column gap-3">
                                                    <Title
                                                        level={3}
                                                        content={`${test.operation.operation?.id ?? 'N/A'}/${test.operation.operation?.label ?? 'N/A'}`}
                                                    />
                                                    <div className={`row g-3 ${styles.scrollable}`}>
                                                        {test.asserts
                                                            .filter(assert =>
                                                                assert.assert?.id?.startsWith(test.operation.operation?.id ?? '')
                                                            )
                                                            .map(assert => (
                                                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                                    <Card>
                                                                        <div className={styles.assertCardContainer}>
                                                                            <Card.Body>
                                                                                <Card.Title>
                                                                                    {assert.assert?.id ?? 'N/A'}
                                                                                </Card.Title>
                                                                                <Card.Text>
                                                                                    <LabelValueDetail
                                                                                        label={i18n.t('label.name')}
                                                                                        value={assert.assert?.label ?? 'N/A'}
                                                                                    />
                                                                                    <LabelValueDetail
                                                                                        label="Direction"
                                                                                        value={assert.assert?.direction ?? 'N/A'}
                                                                                    />
                                                                                    <div>
                                                                                        <Form.Text>
                                                                                            <p className={styles.description}>
                                                                                                {assert.assert?.description ?? 'N/A'}
                                                                                            </p>
                                                                                        </Form.Text>
                                                                                    </div>
                                                                                </Card.Text>
                                                                            </Card.Body>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    {!showOperationButton && (
                                                        <div className="d-flex justify-content-center">
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => {
                                                                    test.toggle = !test.toggle;
                                                                    forceUpdate();
                                                                    setToggleTestOperation(true);
                                                                }}
                                                            >
                                                                {i18n.t('button.seeoperations')}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                // Display operation card when toggle is inactive
                                                toggleTestOperation && (
                                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                                        <Card className="h-100 d-flex flex-column">
                                                            <div className={`${styles.operationCardContainer} d-flex flex-column flex-grow-1`}>
                                                                <Card.Body className="d-flex flex-column flex-grow-1 justify-content-between">
                                                                    <div>
                                                                        <Card.Title>
                                                                            {test.operation.operation?.id ?? 'N/A'}
                                                                        </Card.Title>
                                                                        <Card.Text>
                                                                            <LabelValueDetail
                                                                                label={i18n.t('label.name')}
                                                                                value={test.operation.operation?.label ?? 'N/A'}
                                                                            />
                                                                            <LabelValueDetail
                                                                                label={i18n.t('label.method')}
                                                                                value={test.operation.operation?.method?.toLocaleUpperCase() ?? 'N/A'}
                                                                            />
                                                                            <div className="d-flex gap-2">
                                                                                <Form.Label className="fw-semibold">
                                                                                    {i18n.t('label.fixture')}:
                                                                                </Form.Label>
                                                                                <Form.Text>
                                                                                    <a
                                                                                        href="#fixture"
                                                                                        onClick={() => {
                                                                                            const updatedFixtures = fixtures.map(f => ({
                                                                                                ...f,
                                                                                                toggle: f.fixture.id === test.operation.operation?.targetId || f.fixture.id === test.operation.operation?.sourceId
                                                                                            }));
                                                                                            setFixtures(updatedFixtures);
                                                                                            setClosedFixture(false);
                                                                                        }}
                                                                                    >
                                                                                        {(test.operation.operation?.targetId || test.operation.operation?.sourceId) ?? 'N/A'}
                                                                                    </a>
                                                                                </Form.Text>
                                                                            </div>
                                                                            <div className={styles.description}>
                                                                                <Form.Text>
                                                                                    <p>{test.operation.operation?.description ?? 'N/A'}</p>
                                                                                </Form.Text>
                                                                            </div>
                                                                        </Card.Text>
                                                                    </div>
                                                                    {test.asserts.some(assert =>
                                                                        assert.assert?.id?.startsWith(test.operation.operation?.id ?? '')
                                                                    ) && (
                                                                            <div className="d-flex justify-content-center mt-auto">
                                                                                <Button
                                                                                    variant="primary"
                                                                                    onClick={() => {
                                                                                        test.toggle = !test.toggle;
                                                                                        forceUpdate();
                                                                                        setToggleTestOperation(false);
                                                                                        setClosedFixture(true);
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('button.seeasserts')}
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                </Card.Body>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ))}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <Title level={2} content={'Teardown'} />
                            </Accordion.Header>
                            <Accordion.Body className={styles.scrollable}>
                                <div className="row g-5">
                                    {script.teardown?.action.map(a =>
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <Card>
                                                <div className={styles.operationCardContainer}>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {a.operation?.id ?? 'N/A'}
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <LabelValueDetail
                                                                label={i18n.t('label.name')}
                                                                value={a.operation?.label ?? 'N/A'}
                                                            />
                                                            <LabelValueDetail
                                                                label={i18n.t('label.method')}
                                                                value={a.operation?.method?.toLocaleUpperCase() ?? 'N/A'}
                                                            />
                                                            <div className="d-flex gap-2">
                                                                <Form.Label className="fw-semibold">
                                                                    {i18n.t('label.fixture')}:
                                                                </Form.Label>
                                                                <Form.Text>
                                                                    <a
                                                                        href="#fixture"
                                                                        onClick={() => {
                                                                            const updatedFixtures = fixtures.map(f => ({
                                                                                ...f,
                                                                                toggle: f.fixture.id === a.operation?.targetId || f.fixture.id === a.operation?.sourceId
                                                                            }));
                                                                            setFixtures(updatedFixtures);
                                                                            setClosedFixture(false);
                                                                        }}
                                                                    >
                                                                        {(a.operation?.targetId || a.operation?.sourceId) ?? 'N/A'}
                                                                    </a>
                                                                </Form.Text>
                                                            </div>
                                                            <div className={styles.description}>
                                                                <Form.Text>
                                                                    <p>{a.operation?.description ?? 'N/A'}</p>
                                                                </Form.Text>
                                                            </div>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </div>
                                            </Card>
                                        </div>
                                    )}
                                </div>
                            </Accordion.Body >
                        </Accordion.Item>
                    </Accordion>

                    {!closedFixture &&
                        fixtures.map((fixture) =>
                            fixture.toggle && (
                                <div id="fixture">
                                    <Card>
                                        <Card.Header>
                                            <Title level={2} content={i18n.t('title.fixtures')} />
                                        </Card.Header>
                                        <Card.Body>
                                            <LabelValueDetail
                                                label="ID"
                                                value={fixture.fixture.id ?? 'N/A'}
                                            />
                                            <div className="d-flex gap-2">
                                                <Form.Label className="fw-semibold">
                                                    {i18n.t('label.resourcereference')}:
                                                </Form.Label>
                                                <Form.Text>
                                                    <a
                                                        href="#fixture"
                                                        onClick={() => {
                                                            navigateToFixtureEdition(fixture.fixture.id ?? '');
                                                        }}
                                                    >
                                                        {fixture.fixture.resource?.reference ?? 'N/A'}
                                                    </a>
                                                </Form.Text>
                                            </div>
                                            <LabelValueDetail
                                                label={i18n.t('label.actor')}
                                                value={fixture.fixture.resource?.display ?? 'N/A'}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        )
                    }

                    <Button
                        className="button"
                        variant="primary"
                        onClick={() => {
                            if (testScriptId) {
                                lauchTest(testScriptId);
                            }
                        }}
                    >
                        {i18n.t('button.launch')}
                    </Button>
                </div>
            </>
        </PandoraPage >
    );
};

export default TestScriptViewer;