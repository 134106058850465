// Font awesome
import { faEye, faPlay } from "@fortawesome/free-solid-svg-icons";
// Fhir front library
import { SearchableTable } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useState } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Component
import PandoraPage from '../../components/PandoraPage/PandoraPage';
// React Bootstrap
import { Form } from "react-bootstrap";
// FHIR
import { TestScript } from "fhir/r5";

const TestScripts: FunctionComponent = () => {

    //////////////////////////////
    //         Functions        //
    //////////////////////////////

    /**
      * Check if the TestScript is automatable based on its meta tags.
      * @param testScript The TestScript resource to check.
      * @returns True if automatable, false otherwise.
      */
    function isTestScriptAutomatable(testScript: TestScript): boolean {
        const automatableTag = testScript.meta?.tag?.find(tag => tag.system === "http://isis.com/test/CodeSystem/COS-AutomatableTest" && tag.code === "automatable");
        return automatableTag !== undefined;
    }

    //////////////////////////////
    //        Navigation        //
    //////////////////////////////

    const navigate = useNavigate();

    const onPlay = useCallback((id: string) => {
        navigate("/SystemsSelection/" + id);
    }, [navigate]);

    const onDetail = useCallback((id: string) => {
        navigate("/TestScriptViewer/" + id);
    }, [navigate]);

    //////////////////////////////
    //           Error          //
    //////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    //////////////////////////////
    //          Content         //
    //////////////////////////////

    return (
        <PandoraPage titleKey='title.testscripts' needsLogin={true}>
            <SearchableTable
                searchCriteriaProperties={{
                    title: i18n.t('title.searchcriteria'),
                    submitButtonLabel: i18n.t('button.search'),
                    resetButtonLabel: i18n.t('button.reset'),
                    language: i18n.t,
                    fixedParameters:
                    {
                        _elements: "id,title",
                        _sort: "-_lastUpdated",
                    },
                    inputs: [
                        {
                            label: "ID",
                            type: "text",
                            placeholder: i18n.t('placeholder.id'),
                            searchParamsName: "_id",
                        },
                        {
                            label: i18n.t('label.name'),
                            type: "text",
                            placeholder: i18n.t('placeholder.name'),
                            searchParamsName: "title:contains",
                        },
                    ],
                }}
                paginatedTableProperties={{
                    columns: [
                        {
                            header: "ID",
                            dataField: "id",
                            width: "30%",
                        },
                        {
                            header: i18n.t('label.name'),
                            dataField: "name",
                            width: "45%",
                        },
                        {
                            header: i18n.t('switchbutton.automatable'),
                            dataField: "automatable",
                            width: "15%",
                            formatter: (cell: boolean, row: { id: string }) => {
                                return (
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            disabled={!cell}
                                            checked={cell}
                                            onChange={() => { }}
                                        />
                                    </Form>
                                );
                            },
                        },
                    ],
                    action: [
                        {
                            icon: faEye,
                            onClick: onDetail,
                        },
                        {
                            icon: faPlay,
                            onClick: onPlay,
                        },
                    ],
                    mapResourceToData: (resource: any) => {
                        return {
                            id: resource.id,
                            name: resource.title,
                            automatable: isTestScriptAutomatable(resource),
                        };
                    },
                    searchProperties: {
                        serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                        resourceType: 'TestScript',
                    },
                    onError: onError,
                }}
            />
        </PandoraPage>
    );
};

export default TestScripts;