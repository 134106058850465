// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EvaluationReport_cardContainer__Z9Xsq {
  margin-top: 3rem;
}

.EvaluationReport_formTextLabel__9UBOq {
  margin: 0 1rem 0 1.5rem;
  text-align: justify;
  line-height: 1.2rem;
}

.EvaluationReport_label__514wf {
  margin-right: 0.5rem;
}

.EvaluationReport_notificationContent__uGPaV {
  overflow: auto;
}

.EvaluationReport_separator__DWdZX {
  border: 1px dashed var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/evaluationReport/EvaluationReport.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".cardContainer {\r\n  margin-top: 3rem;\r\n}\r\n\r\n.formTextLabel {\r\n  margin: 0 1rem 0 1.5rem;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.label {\r\n  margin-right: 0.5rem;\r\n}\r\n\r\n.notificationContent {\r\n  overflow: auto;\r\n}\r\n\r\n.separator {\r\n  border: 1px dashed var(--secondary-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `EvaluationReport_cardContainer__Z9Xsq`,
	"formTextLabel": `EvaluationReport_formTextLabel__9UBOq`,
	"label": `EvaluationReport_label__514wf`,
	"notificationContent": `EvaluationReport_notificationContent__uGPaV`,
	"separator": `EvaluationReport_separator__DWdZX`
};
export default ___CSS_LOADER_EXPORT___;
