// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exchangeDetails_label__j35Rf {
    margin: 0 1rem 1rem 0;
    line-height: 1.2rem;
}

.exchangeDetails_alertTitle__4vPAC {
    margin-left: 1rem;
}

.exchangeDetails_headerTableContainer__R5tEw {
    background-color: var(--gray);
}

.exchangeDetails_tableTitle__cavMe {
    text-align: center;
    font-size: var(--title-3-desktop-size);
    font-style: var(--title);
    font-weight: 100;
}

.exchangeDetails_table__xb6VB {
    margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/exchangeDetails/exchangeDetails.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,sCAAsC;IACtC,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".label {\r\n    margin: 0 1rem 1rem 0;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.alertTitle {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.headerTableContainer {\r\n    background-color: var(--gray);\r\n}\r\n\r\n.tableTitle {\r\n    text-align: center;\r\n    font-size: var(--title-3-desktop-size);\r\n    font-style: var(--title);\r\n    font-weight: 100;\r\n}\r\n\r\n.table {\r\n    margin-left: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `exchangeDetails_label__j35Rf`,
	"alertTitle": `exchangeDetails_alertTitle__4vPAC`,
	"headerTableContainer": `exchangeDetails_headerTableContainer__R5tEw`,
	"tableTitle": `exchangeDetails_tableTitle__cavMe`,
	"table": `exchangeDetails_table__xb6VB`
};
export default ___CSS_LOADER_EXPORT___;
