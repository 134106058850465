// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestPlanSystemsSelection_main__Bhwmq {
    width: 80%;
    margin: auto;
}

.TestPlanSystemsSelection_card__ra5KF {
    margin-bottom: 3rem;
}

.TestPlanSystemsSelection_formSelect__x6owc {
    margin: 0 2rem 0 1.5rem;
    width: 100%;
}

.TestPlanSystemsSelection_formTextLabel__MrEPf {
    margin: 0 1rem 0 1.5rem;
    text-align: justify;
    line-height: 1.2rem;
}

.TestPlanSystemsSelection_formLabel__-ZBE4 {
    margin: 0 1rem 1rem 0;
    text-align: justify;
    line-height: 1.2rem;
}

.TestPlanSystemsSelection_btnContainer__o7CTj {
    margin-left: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testPlanSystemsSelection/TestPlanSystemsSelection.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".main {\r\n    width: 80%;\r\n    margin: auto;\r\n}\r\n\r\n.card {\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.formSelect {\r\n    margin: 0 2rem 0 1.5rem;\r\n    width: 100%;\r\n}\r\n\r\n.formTextLabel {\r\n    margin: 0 1rem 0 1.5rem;\r\n    text-align: justify;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n    margin: 0 1rem 1rem 0;\r\n    text-align: justify;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.btnContainer {\r\n    margin-left: 1.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `TestPlanSystemsSelection_main__Bhwmq`,
	"card": `TestPlanSystemsSelection_card__ra5KF`,
	"formSelect": `TestPlanSystemsSelection_formSelect__x6owc`,
	"formTextLabel": `TestPlanSystemsSelection_formTextLabel__MrEPf`,
	"formLabel": `TestPlanSystemsSelection_formLabel__-ZBE4`,
	"btnContainer": `TestPlanSystemsSelection_btnContainer__o7CTj`
};
export default ___CSS_LOADER_EXPORT___;
