// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validationReports_checkIcon__uqDXn {
    color: #8BC74E;
}

.validationReports_iconContainer__8cguH {
    text-align: center;
}

.validationReports_errorIcon__TDCmt {
    color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/validationReports/validationReports.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".checkIcon {\r\n    color: #8BC74E;\r\n}\r\n\r\n.iconContainer {\r\n    text-align: center;\r\n}\r\n\r\n.errorIcon {\r\n    color: var(--primary-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkIcon": `validationReports_checkIcon__uqDXn`,
	"iconContainer": `validationReports_iconContainer__8cguH`,
	"errorIcon": `validationReports_errorIcon__TDCmt`
};
export default ___CSS_LOADER_EXPORT___;
