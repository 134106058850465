// Components
import { FhirStatus, StatusAlert, StatusIcon, Title } from "@fyrstain/fhir-front-library";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
// FHIR
import Client from "fhir-kit-client";
import { OperationOutcome, Parameters } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// React Bootstrap
import { Button, Form, ProgressBar } from "react-bootstrap";
// React Router
import { useNavigate, useParams } from "react-router-dom";
// Style
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./packageLoader.module.css";

const PackageLoader: FunctionComponent = () => {

    /////////////////////////////////////////////
    //                 State                   //
    /////////////////////////////////////////////

    const { validatorParam } = useParams();

    const [packageName, setPackageName] = useState("");
    const [file, setFile] = useState(null as File | null);
    const [outcome, setOutcome] = useState(null as OperationOutcome | null);
    const [loading, setLoading] = useState(false);

    /////////////////////////////////////////////
    //                  Client                 //
    /////////////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////////////
    //                 Functions               //
    /////////////////////////////////////////////

    useEffect(() => {
    }, []);

    const navigate = useNavigate();

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);


    //TODO Refactor to use $load-package
    /**
      * Send validation to populate the Validation report page.
      */
    function loadPackage() {
        setLoading(true);
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/fhir+json');
        buildParametersAndSend();
    }

    /**
      * Build parameters and send to load the package into the Validator.
      */
    function buildParametersAndSend() {
        const parameters: Parameters = { resourceType: "Parameters" } as Parameters;
        parameters.parameter = [];

        parameters.parameter.push({ name: "validatorId", valueString: validatorParam ? validatorParam : "" });

        if (file !== null) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {

                parameters?.parameter?.push({ name: "package", valueBase64Binary: reader.result ? (reader.result as string).substring(37) : "" });
                callValidation(parameters);
            };
        }
    }

    /**
     * Call validation from the back to populate the Validation report page.
     *
     * @param parameters    the parameters to the Structure Definition.
     */
    function callValidation(parameters: Parameters) {
        fhirClient.operation({
            name: "load-package",
            method: 'POST',
            input: parameters
        })
            .then(outcome => {
                setOutcome(outcome);
                setFile(null);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                onError();
            });
    }

    /**
      * Reset the values of the resource, the resource of the file and the option of the select.
      */
    function onReset() {
        setLoading(true);
        const input = document.getElementById('inputFile') as HTMLInputElement;
        input.value = '';
        setFile(null);
        setOutcome(null);
        setLoading(false);
    }

    /**
     * Capitalize the first letter 
     * 
     * @param string the string to capitalize
     */
    function capitalizeFirst(string: string): string {
        const splitted = string.split('');
        splitted[0] = splitted[0].toUpperCase();
        return splitted.join('');
    }

    /**
    * Count the percentage of the differents status of the issues to populate the progressBar.
    * 
    * @param severityStatus    the severity status to the Validation report bundle.
    */
    function countPercentageStatusIssues(severityStatus: string): number {
        const severity = outcome?.issue.map(s => s.severity);
        return severity ? severity.filter(s => s === severityStatus).length * 100 / severity.length : 0;
    }

    /**
     * Count the number of the differents status of the issues to populate the progressBar.
     * 
     * @param severityStatus    the severity status to the Validation report bundle.
     */
    function countStatusIssues(severityStatus: string): number {
        return outcome? outcome.issue.map(s => s.severity).filter(s => s === severityStatus).length : 0;
    }

    /////////////////////////////////////////////
    //                Content                  //
    /////////////////////////////////////////////

    return (
        //TODO Translate
        <PandoraPage titleKey={i18n.t('title.packageuploader')} loading={loading} needsLogin={false} >
            <div className={styles.validatorHome}>
                <fieldset
                    id="uploadInput"
                >
                    <Form.Group
                        controlId="formFile"
                        className="mb-3"
                    >
                        <Form.Label className={styles.formLabel}>
                            {i18n.t("general.uploadpackage")}
                        </Form.Label>
                        <Form.Control
                            id="inputFile"
                            type="file"
                            onChange={(e) => {
                                const target = e.target as HTMLInputElement;
                                setFile(target.files ? target.files[0] : null);
                                setPackageName(target.files ? target.files[0].name : "")
                            }}
                        />
                    </Form.Group>
                </fieldset>
                <Button
                    className="button"
                    variant="danger"
                    onClick={loadPackage}
                    disabled={file === null}
                >
                    {i18n.t("button.upload")}
                </Button>
                <Button
                    className="button"
                    variant="secondary"
                    onClick={onReset}
                >
                    {i18n.t('button.reset')}
                </Button>

                {outcome ?
                    <>
                        <Title level={2} prefix={"title.loadedcontent" + " :"} content={packageName}></Title>

                        <div className={styles.summaryStatusContainer}>
                            <Form.Label>
                                <strong className={styles.summaryStatusLabel}>
                                    {i18n.t("label.statussummary")} :
                                </strong>
                            </Form.Label>
                            <div className={styles.progressBarContainer}>
                                <Form.Text>
                                    <ProgressBar>
                                        <ProgressBar variant="warning" now={countPercentageStatusIssues('warning')} label={countStatusIssues('warning')} />
                                        <ProgressBar variant="danger" now={countPercentageStatusIssues('error')} label={countStatusIssues('error')} />
                                        <ProgressBar variant="info" now={countPercentageStatusIssues('information')} label={countStatusIssues('information')} />
                                        <ProgressBar variant="dark" now={countPercentageStatusIssues('fatal')} label={countStatusIssues('fatal')} />
                                    </ProgressBar>
                                </Form.Text>
                            </div>
                        </div>

                        {outcome.issue.map(issue =>
                            <div className={styles.alert}>
                                <StatusAlert
                                    status={FhirStatus[issue.severity as keyof typeof FhirStatus]}
                                >
                                    <div className={styles.alertContainer}>
                                        <div>
                                            <StatusIcon
                                                status={FhirStatus[issue.severity as keyof typeof FhirStatus]}
                                            />
                                            <strong className={styles.alertTitle}>
                                                {capitalizeFirst(issue.severity)}
                                            </strong>
                                        </div>
                                        <div className={styles.notificationContent}>
                                            <div className={styles.labelsNotification}>
                                                <div className={styles.formTextLabel}>
                                                    <Form.Label>
                                                        <strong className={styles.label}>
                                                            Code :
                                                        </strong>
                                                    </Form.Label>
                                                    <Form.Text >
                                                        {issue.code ? issue.code : 'N/A'}
                                                    </Form.Text>
                                                </div>
                                                <div className={styles.formTextLabel}>
                                                    <Form.Label>
                                                        <strong className={styles.label}>
                                                            Diagnostics :
                                                        </strong>
                                                    </Form.Label>
                                                    <Form.Text >
                                                        {issue.diagnostics ? issue.diagnostics : 'N/A'}
                                                    </Form.Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </StatusAlert>
                            </div>
                        )}
                    </>
                    :
                    <></>
                }
            </div>
        </PandoraPage>
    );
};

export default PackageLoader;

