// Font awesome
import { faEye, faPlay } from "@fortawesome/free-solid-svg-icons";
// Fhir front library
import { FhirStatus, SearchableTable, StatusTag } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Components
import PandoraPage from '../../components/PandoraPage/PandoraPage';

const TestPlans: FunctionComponent = () => {

    //////////////////////////////
    //        Navigation        //
    //////////////////////////////

    const navigate = useNavigate();

    const onDetails = useCallback((id: string) => {
        navigate("/TestPlanDetails/" + id);
    }, [navigate]);

    const onLaunch = useCallback((id: string) => {
        navigate("/TestPlan/SystemSelection/" + id);
    }, [navigate]);

    //////////////////////////////
    //           Error          //
    //////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    //////////////////////////////
    //          Content         //
    //////////////////////////////

    return (
        <PandoraPage titleKey='title.testplan' needsLogin={true}>
            <SearchableTable
                searchCriteriaProps={{
                    title: i18n.t('title.searchcriteria'),
                    primaryButtonLabel: i18n.t('button.search'),
                    secondaryButtonLabel: i18n.t('button.reset'),
                    language: i18n.t,
                    searchResultModifier:
                    {
                        _elements: "id,title,status",
                        _sort: "-_lastUpdated",
                        "_tag:not": "https://fyrstain.com/testPlanType|Instance",
                    },
                    criteria: [
                        {
                            inputProps: {
                                label: "ID",
                                type: "text",
                            },
                            searchParamsName: "_id",
                        },
                        {
                            inputProps: {
                                label: i18n.t('label.name'),
                                type: "text",
                            },
                            searchParamsName: "title:contains",
                        },
                    ],
                }}
                paginationTableProps={{
                    columns: [
                        {
                            header: "ID",
                            dataField: "id",
                            width: "25%",
                        },
                        {
                            header: i18n.t('label.name'),
                            dataField: "Name",
                            width: "40%",
                        },
                        {
                            header: i18n.t('label.status'),
                            dataField: "Status",
                            width: "20%",
                            formatter: (cell: keyof typeof FhirStatus) => {
                                return <StatusTag
                                    status={FhirStatus[cell]}
                                    statusMessage={cell}
                                />;
                            },
                        },
                    ],
                    action: [
                        {
                            icon: faEye,
                            onClick: onDetails,
                        },
                        {
                            icon: faPlay,
                            onClick: onLaunch,
                        },
                    ],
                    mapResourceToData: (resource: any) => {
                        return {
                            id: resource.id,
                            Name: resource.title,
                            Status: resource.status,
                        };
                    },
                    searchProps: {
                        serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                        resourceType: 'TestPlan',
                    },
                    onError: onError,
                }}
            />
        </PandoraPage>
    );
};

export default TestPlans;