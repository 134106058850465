// Font awesome
import { faCheck, faEye, faScaleBalanced, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Fhir front library
import { SearchableTable } from '@fyrstain/fhir-front-library';
// Date
import dayjs from 'dayjs';
// FHIR
import Client from 'fhir-kit-client';
import { Bundle, BundleEntry, Parameters } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useState } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Component
import PandoraPage from '../../components/PandoraPage/PandoraPage';
// Style
import styles from './evaluationReports.module.css';
// React Bootstrap
import { Overlay, Tooltip } from 'react-bootstrap';


const EvaluationReports: FunctionComponent = () => {

    ////////////////////////////////
    //            State           //
    ////////////////////////////////

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [target, setTarget] = useState(null);

    ////////////////////////////////
    //          Client           //
    ////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
    });

    /////////////////////////////////////
    //            Navigation           //
    /////////////////////////////////////

    const navigate = useNavigate();

    /**
     * Navigate to the test report details page if exist.
     */
    const handleIconClick = async (id: string, event: any) => {
        event.stopPropagation();
        const response = await fhirClient.search({
            resourceType: 'TestReport',
            searchParams: {
                _elements: '_id',
                _filter: 'report-test-link sw "Bundle/' + id + '" or report-setup-link sw "Bundle/' + id + '"'
            }
        });
        if (response.total === 0) {
            setTarget(event.target);
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setTarget(null);
            }, 2000);
        } else {
            navigate("/TestReportDetails/" + response.entry?.[0].resource?.id);
        }
    };

    const onEvaluationReportDetails = useCallback((id: string) => {
        navigate("/EvaluationReport/" + id);
    }, [navigate]);

    /////////////////////////////////////
    //             Error               //
    /////////////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //           Functions             //
    /////////////////////////////////////

    /**
    * Get test report to populate the table.
    * 
    * @param bundle    the bundle to the Evaluation report.
    */
    async function getTestReport(bundle: Bundle) {
        const idMap = new Map<string, string | undefined>();
        const evaluationReportId = bundle.id || '';
        const response = await fhirClient.search({
            resourceType: 'TestReport',
            searchParams: {
                _elements: '_id',
                _filter: 'report-test-link sw "Bundle/' + evaluationReportId + '" or report-setup-link sw "Bundle/' + evaluationReportId + '"'
            }
        });
        response.entry?.forEach((entry: BundleEntry) => {
            if (entry.resource?.resourceType === 'TestReport') {
                idMap.set(evaluationReportId, entry.resource.id);
            }
        });
        return idMap;
    }

    /**
 * Get issued to populate the field.
 * 
 * @param bundle    the bundle to the Validation report.
 */
    function getIssued(bundle: Bundle): string {
        let issued = 'N/A';
        issued = bundle.meta?.lastUpdated ? bundle.meta?.lastUpdated : issued;
        return issued;
    }

    /////////////////////////////////
    //           Content           //
    /////////////////////////////////

    return (
        <PandoraPage titleKey='title.evaluationreports' loading={loading} needsLogin={true} >
            <>
                {showAlert && target && (
                    <Overlay target={target}
                        show={true}
                        placement="top">
                        <Tooltip>
                            {i18n.t('errormessage.notlinkedevaluation')}
                        </Tooltip>
                    </Overlay>
                )}
                <SearchableTable
                    searchCriteriaProps={{
                        title: i18n.t('title.searchcriteria'),
                        primaryButtonLabel: i18n.t('button.search'),
                        secondaryButtonLabel: i18n.t('button.reset'),
                        language: i18n.t,
                        searchResultModifier:
                        {
                            _sort: "-_lastUpdated",
                        },
                        criteria: [
                            {
                                inputProps: {
                                    label: "ID",
                                    type: "text",
                                },
                                searchParamsName: "_id",
                            }
                        ],
                    }}
                    paginationTableProps={{
                        columns: [
                            {
                                header: i18n.t('label.intest?'),
                                dataField: 'testReport',
                                width: "12%",
                                formatter: (cell: string | undefined) => {
                                    return <div className={styles.iconContainer}>
                                        {cell && cell.length > 0 ? <FontAwesomeIcon
                                            className={styles.checkIcon}
                                            icon={faCheck}
                                        /> : <FontAwesomeIcon
                                            className={styles.errorIcon}
                                            icon={faXmark}
                                        />
                                        }
                                    </div>;
                                },
                            },
                            {
                                header: "ID",
                                dataField: "id",
                                width: "39%",
                                tabletWidth: "22%",
                            },
                            {
                                header: i18n.t('label.issued'),
                                dataField: 'issued',
                                width: "39%",
                                formatter: (cell: string) => {
                                    return <>{dayjs(cell).fromNow()}</>;
                                },
                            }
                        ],
                        action: [
                            {
                                icon: faEye,
                                onClick: onEvaluationReportDetails,
                            },
                            {
                                icon: faScaleBalanced,
                                onClick: handleIconClick
                            }
                        ],
                        mapResourceToData: async (resource: any) => {
                            const bundle = resource as Bundle;
                            const testReportAsync = await getTestReport(bundle);
                            return {
                                id: resource.id,
                                issued: getIssued(resource),
                                testReport: testReportAsync.get(resource.id),
                            };
                        },
                        searchProps: {
                            serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                            resourceType: 'Bundle',
                            searchParams: {
                                "composition.type": "evaluation-report"
                            },
                        },
                        onError: onError,
                    }}
                />
            </>
        </PandoraPage>
    );
}

export default EvaluationReports;
