// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.packageLoader_alert__287np {
    width: 97%;
    margin: auto;
}

.packageLoader_label__l9axV {
    margin-right: 0.5rem;
}

.packageLoader_alertTitle__kdxTQ {
    margin-left: 1rem;
}

.packageLoader_notificationContent__LAaMs {
    display: flex;
    justify-content: space-between;
}

.packageLoader_labelsNotification__O9VUv {
    margin-top: 1rem;
}

.packageLoader_formTextLabel__7v4ZE {
    margin: 0 1rem 0 1.5rem;
    text-align: justify;
    line-height: 1.2rem;
}

.packageLoader_summaryStatusContainer__C-6HR {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 2rem 0;
}

.packageLoader_summaryStatusLabel__PmHEJ {
    width: 10%;
    margin-right: 3rem;
}

.packageLoader_progressBarContainer__\\+w8Of {
    width: 78%;
    margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/packageLoader/packageLoader.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".alert {\r\n    width: 97%;\r\n    margin: auto;\r\n}\r\n\r\n.label {\r\n    margin-right: 0.5rem;\r\n}\r\n\r\n.alertTitle {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.notificationContent {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.labelsNotification {\r\n    margin-top: 1rem;\r\n}\r\n\r\n.formTextLabel {\r\n    margin: 0 1rem 0 1.5rem;\r\n    text-align: justify;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.summaryStatusContainer {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 2rem 0 2rem 0;\r\n}\r\n\r\n.summaryStatusLabel {\r\n    width: 10%;\r\n    margin-right: 3rem;\r\n}\r\n\r\n.progressBarContainer {\r\n    width: 78%;\r\n    margin-left: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `packageLoader_alert__287np`,
	"label": `packageLoader_label__l9axV`,
	"alertTitle": `packageLoader_alertTitle__kdxTQ`,
	"notificationContent": `packageLoader_notificationContent__LAaMs`,
	"labelsNotification": `packageLoader_labelsNotification__O9VUv`,
	"formTextLabel": `packageLoader_formTextLabel__7v4ZE`,
	"summaryStatusContainer": `packageLoader_summaryStatusContainer__C-6HR`,
	"summaryStatusLabel": `packageLoader_summaryStatusLabel__PmHEJ`,
	"progressBarContainer": `packageLoader_progressBarContainer__+w8Of`
};
export default ___CSS_LOADER_EXPORT___;
