// React
import React, { useCallback, useEffect, useState, useRef } from 'react';
// Components
import { FhirStatus, StatusAlert, StatusIcon } from '@fyrstain/fhir-front-library';
// Styles
import styles from './TestReportResultAlert.module.css';
// Client
import Client from 'fhir-kit-client';
// FHIR
import { TestReport } from 'fhir/r5';
// Navigation
import { useNavigate } from 'react-router-dom';

////////////////////////////////////////
//              Props                 //
////////////////////////////////////////

interface TestReportStatusAlertProps {
    // The TestReport resource
    testReport: TestReport;
}

const TestReportResultAlert: React.FC<TestReportStatusAlertProps> = (configs) => {

    ////////////////////////////////////////
    //              State                 //
    ////////////////////////////////////////

    const [result, setResult] = useState<FhirStatus>(FhirStatus.unknown);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    ////////////////////////////////////////
    //             Constants              //
    ////////////////////////////////////////

    const allowedResults = ['pass', 'fail', 'pending'];
    const dropdownRef = useRef<HTMLDivElement>(null);

    const availableResults = allowedResults.filter(
        (status) => status !== result
    );

    ////////////////////////////////////////
    //              Client                //
    ////////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    //////////////////////////////////////// 
    //              Functions             //
    ////////////////////////////////////////

    const navigate = useNavigate();

    /**
     * Navigate to the Error page
     */
    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /** Get the path and result from the TestReport resource
     * 
     * @param report 
     * 
     */
    const getPathAndResult = (report: any) => {
        const path = report.id.split('/');
        const result = report.result;
        return { path, result };
    };

    /** Create a patch document to update the result of a TestReport resource
     * 
     * @param result 
     * 
     */
    const createPatchDocument = (result: string) => {
        return [
            {
                op: 'replace',
                path: '/result',
                value: result
            }
        ];
    };

    /** Patch a TestReport resource
     * 
     * @param patchDocument 
     * 
     */
    const patchResource = async (patchDocument: any) => {
        try {
            const updatedTestReport = await fhirClient.patch({
                resourceType: 'TestReport',
                id: configs.testReport?.id ?? '',
                JSONPatch: patchDocument,
            });
            return updatedTestReport;
        } catch (error) {
            onError();
        }
    };

    /**
     * Initialize the result state based on the test report.
     */
    const initializeResult = () => {
        if (configs.testReport) {
            const { result } = getPathAndResult(configs.testReport);
            setResult(FhirStatus[result as keyof typeof FhirStatus]);
        }
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    };

    ////////////////////////////////////////
    //             LifeCycle              //
    ////////////////////////////////////////

    /** 
     * Initialize the result state when the component mounts
     */
    useEffect(() => {
        initializeResult();
    }, [configs.testReport]);

    /** Add the event listener to handle the click outsideof the dropdown
     * 
     */
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    ////////////////////////////////////////
    //             Handlers               //
    ////////////////////////////////////////

    /** Handle the alert click
     * 
     */
    const handleAlertClick = () => {
        setShowDropdown(!showDropdown);
    };

    /** Handle the result change
     * 
     * @param newStatus 
     * 
     */
    const handleResultChange = (newStatus: keyof typeof FhirStatus) => {
        const patchDocument = createPatchDocument(newStatus);
        patchResource(patchDocument);
        setResult(FhirStatus[newStatus]);
        setShowDropdown(false);
    };

    /** Handle the click outside
     * 
     * @param event 
     * 
     */
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    };

    ////////////////////////////////////////
    //             Content                //
    ////////////////////////////////////////

    return (
        <div className={styles.TestReportAlertcontainer}
            ref={dropdownRef}
        >
            <StatusAlert
                status={result}
                onClick={handleAlertClick}
            >
                <div>
                    <StatusIcon status={result} />
                    <strong className={styles.alertTitle}>
                        {configs.testReport?.id} / {configs.testReport?.name}
                    </strong>
                </div>
            </StatusAlert>
            {showDropdown && (
                <div className={styles.TestReportResultDropdown}>
                    {availableResults.map((statusKey) => (
                        <div
                            key={statusKey}
                            className={styles.TestReportResultDropdownItems}
                            onClick={() => handleResultChange(statusKey as keyof typeof FhirStatus)}
                        >
                            {statusKey}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TestReportResultAlert;