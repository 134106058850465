import { FunctionComponent } from "react";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
import styles from "./InProgress.module.css";
import { Title } from "@fyrstain/fhir-front-library";
import i18n from "i18next";

const InProgress: FunctionComponent = () => {

    return <PandoraPage loading={false} needsLogin={false} >
        <div className={styles.mainErrorContainer}>
            <img
                className={styles.imageIcon}
                src="/assets/InProgress.png"
                alt="Oops !"
            />
            <Title level={1} prefix={i18n.t('title.inprogress.wip')} content={i18n.t('title.inprogress.comingsoon')} />
        </div>
    </PandoraPage>
};

export default InProgress;