//React
import { FunctionComponent, useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//FHIR
import Client, { FhirResource } from "fhir-kit-client";
import { Bundle, Device, Endpoint } from "fhir/r5";
import { SimpleCode, ValueSetLoader } from "@fyrstain/fhir-front-library";
//Icons
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Components
import { Title } from "@fyrstain/fhir-front-library";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import PandoraPage from "../../components/PandoraPage/PandoraPage";
//Translation
import i18n from "i18next";
//Styles
import styles from "./systemRegistration.module.css";

interface State {
    endpointId: string,
    // Device
    active: boolean,
    name: string,
    deviceType: string,
    deviceVersion: string,
    standard: string,
    standardVersion: string,
    // Endpoint
    endpointName: string,
    endpointStatus: string,
    endpointConnectionType: string,
    endpointEnvironmentType: string,
    endpointUrl: string,
    // ValueSet related
    versionsUrl: string,
    deviceTypes: SimpleCode[],
    standards: SimpleCode[],
    standardsVersions: SimpleCode[],
    endpointStatuses: SimpleCode[],
    endpointConnectionTypes: SimpleCode[],
    endpointEnvironmentTypes: SimpleCode[],
}

// TOOD see what we do when it's a modification of a System
const SystemRegistration: FunctionComponent = () => {

    /////////////////////////////////////
    //           Constants             //
    /////////////////////////////////////
    const systemTypesUrl = process.env.REACT_APP_VALUESET_SYSTEMTYPES_URL ?? 'http://fyrstain.com/pdt/ValueSet/systemTypes';
    const standardsUrl = process.env.REACT_APP_VALUESET_STANDARDCODES_URL ?? 'http://fyrstain.com/pdt/ValueSet/standardCodes';
    const endpointStatusUrl = process.env.REACT_APP_VALUESET_ENDPOINTSTATUS_URL ?? 'http://hl7.org/fhir/ValueSet/endpoint-status';
    const endpointConnectionTypeValueSetUrl = process.env.REACT_APP_VALUESET_ENDPOINTCONNECTIONTYPE_URL ?? 'http://fyrstain.com/pdt/ValueSet/endpoint-connection-type'
    const endpointEnvironmentsUrl = process.env.REACT_APP_VALUESET_ENDPOINTENVIRONMENTS_URL ?? 'http://hl7.org/fhir/ValueSet/endpoint-environment';
    const endpointConnectionTypeCodeSystemUrl = process.env.REACT_APP_CODESYSTEM_ENDPOINTCONNECTIONTYPE_URL ?? 'http://terminology.hl7.org/CodeSystem/endpoint-connection-type';
    const pandoraStandardsCodeSystemUrl = process.env.REACT_APP_CODESYSTEM_PANDORASTANDARDS ?? 'http://fyrstain.com/pdt/CodeSystem/PandoraStandards';
    const endpointEnvironmentsCodeSystemUrl = process.env.REACT_APP_CODESYSTEM_ENDPOINTENVIRONMENTS_URL ?? 'http://hl7.org/fhir/endpoint-environment';

    /////////////////////////////////////
    //             States              //
    /////////////////////////////////////
    //Parameters
    const { systemId } = useParams();

    const [counter, forceUpdate] = useReducer(x => x + 1, 0);
    const [state, setState] = useState({
        endpointId: '' as string,
        // Device
        active: true as boolean,
        name: '' as string,
        deviceType: '' as string,
        deviceVersion: '' as string,
        standard: '' as string,
        standardVersion: '' as string,
        // Endpoint
        endpointName: '' as string,
        endpointStatus: '' as string,
        endpointConnectionType: '' as string,
        endpointEnvironmentType: '' as string,
        endpointUrl: '' as string,
        // ValueSet related
        versionsUrl: '' as string,
        deviceTypes: [] as SimpleCode[],
        standards: [] as SimpleCode[],
        standardsVersions: [] as SimpleCode[],
        endpointStatuses: [] as SimpleCode[],
        endpointConnectionTypes: [] as SimpleCode[],
        endpointEnvironmentTypes: [] as SimpleCode[],
    } as State);

    /**
     * Gets a SimpleCode from the given list based on its string representation.
     * 
     * @param simpleValueSet    the list of codes.
     * @param value             the string representation of the code.
     * @returns the SimpleCode.
     */
    function getCode(simpleValueSet: SimpleCode[], value: string | undefined): SimpleCode | undefined {
        if (!value || value === "undefined") {
            return undefined;
        }
        const splitValue = value.split('|');
        if (splitValue.length === 2) {
            return simpleValueSet.find(simpleCode => simpleCode.system === splitValue[0] && simpleCode.code === splitValue[1]);
        } else {
            return simpleValueSet.find(simpleCode => simpleCode.code === splitValue[0] || simpleCode.display === splitValue[0])
        }
    }

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        set(event.target.name, event.target.value);
    }

    function set(name: string, value: any) {
        console.log(JSON.stringify({ ...state, [name]: value }))
        setState({ ...state, [name]: value })
    }

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    const valueSetLoader = new ValueSetLoader(fhirClient);

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    const navigate = useNavigate();

    const toSystemList = useCallback(() => {
        navigate("/Systems");
    }, [navigate]);

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    function onValidateForm() {
        onValidate();
    }

    async function onValidate() {
        const bundle = createBundle();
        fhirClient.transaction({ body: bundle as FhirResource & { type: "transaction" } })
            .then(response => {
                //TODO Check transactionResponse
                console.log(response);
                toSystemList();
            })
            .catch(error => {
                console.error(error)
                onError();
            });
    }

    function createBundle(): Bundle {
        const bundle = { "resourceType": "Bundle" } as Bundle;
        bundle.type = 'transaction';
        bundle.entry = [];
        bundle.entry.push(
            {
                "fullUrl": "urn:uuid:1",
                "resource": createEndpoint(),
                "request": {
                    "method": systemId ? "PUT" : "POST",
                    "url": systemId ? "Endpoint/" + state.endpointId : "Endpoint"
                }
            },
            {
                "fullUrl": "urn:uuid:2",
                "resource": createDevice(),
                "request": {
                    "method": systemId ? "PUT" : "POST",
                    "url": systemId ? "Device/" + systemId : "Device"
                }
            }
        )
        return bundle;
    }

    function createDevice(): Device {
        const device = { "resourceType": "Device" } as Device;
        device.id = systemId ?? "device";
        device.displayName = state.name;
        device.name = []
        device.name.push(
            {
                "type": "patient-reported-name",
                "value": state.name,
                "display": true
            }
        );
        device.status = state.active ? "active" : "inactive";
        const deviceTypeCode = getCode(state.deviceTypes, state.deviceType);
        if (deviceTypeCode) {
            device.type = [valueSetLoader.toCodeableConcept(deviceTypeCode)];
        }
        device.version = [];
        device.version.push(
            {
                "type": {
                    "coding": [{
                        "system": "urn:iso:std:iso:11073:10101",
                        "code": "531975",
                        "display": "Software revision"
                    }]
                },
                "value": state.deviceVersion
            }
        )
        const standardCode = getCode(state.standards, state.standard);
        const standardVersionCode = getCode(state.standardsVersions, state.standardVersion);
        if (standardCode && standardVersionCode) {
            device.version.push(
                {
                    "type": valueSetLoader.toCodeableConcept(standardCode),
                    "value": standardVersionCode?.display ?? ""
                }
            )
        }
        //TODO Better handling with multiple endpoints
        device.endpoint = [];
        device.endpoint.push(
            {
                "reference": systemId ? "Endpoint/" + state.endpointId : "urn:uuid:1"
            }
        )
        return device;
    }

    function createEndpoint(): Endpoint {
        const endpoint = { "resourceType": "Endpoint" } as Endpoint;
        endpoint.id = systemId ? state.endpointId : "endpoint";
        const endpointStatusCode = getCode(state.endpointStatuses, state.endpointStatus);
        endpoint.status = endpointStatusCode?.code as typeof endpoint.status ?? 'off';
        const endpointConnectionTypeCode = getCode(state.endpointConnectionTypes, state.endpointConnectionType);
        if (endpointConnectionTypeCode) {
            endpoint.connectionType = [];
            endpoint.connectionType.push(valueSetLoader.toCodeableConcept(endpointConnectionTypeCode))
        }
        endpoint.name = state.endpointName;
        //TODO Endpoint description
        const endpointEnvironmentTypeCode = getCode(state.endpointEnvironmentTypes, state.endpointEnvironmentType);
        if (endpointEnvironmentTypeCode) {
            endpoint.environmentType = [];
            endpoint.environmentType.push(valueSetLoader.toCodeableConcept(endpointEnvironmentTypeCode));
        }
        //TODO Managing Organization
        endpoint.address = state.endpointUrl;
        return endpoint;
    }

    /////////////////////////////////////
    //          Page Loading           //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadPage();
    }, []);

    /**
     * Load the ValueSets used for select fields.
     */
    async function loadPage() {
        setLoading(true);
        const loaded = state;
        await loadValueSets(loaded);
        await loadSystemData(loaded);
        setState(loaded);
        setLoading(false);
    }

    /**
     * Load all ValueSet needed to the loading of the Page.
     * 
     * @param loaded the state that is being loaded.
     */
    async function loadValueSets(loaded: State) {
        try {
            loaded.standards = await valueSetLoader.searchValueSet(standardsUrl);
            loaded.deviceTypes = await valueSetLoader.searchValueSet(systemTypesUrl);
            loaded.endpointStatuses = await valueSetLoader.searchValueSet(endpointStatusUrl);
            loaded.endpointConnectionTypes = await valueSetLoader.searchValueSet(endpointConnectionTypeValueSetUrl);
            loaded.endpointEnvironmentTypes = await valueSetLoader.searchValueSet(endpointEnvironmentsUrl);
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /**
     * Load the Device and Enpoint resources to display if the systemId parameter is used.
     * 
     * @param loaded the state that is being loaded.
     */
    async function loadSystemData(loaded: State) {
        if (systemId) {
            try {
                const response = await fhirClient.search({
                    resourceType: 'Device',
                    searchParams: { "_id": systemId ?? '', "_include": "Device:endpoint" }
                })
                const bundle: Bundle = response as Bundle;
                if (bundle.entry) {
                    for (const entry of bundle.entry) {
                        if ('Device' === entry.resource?.resourceType) {
                            const device = entry.resource as Device;
                            loaded.active = device.status === "active";
                            loaded.name = device.displayName ?? "";

                            const systemTypeString = device.type?.filter(
                                type => type.coding?.find(coding => coding.system === systemTypesUrl)
                            ).map(
                                type => type.coding?.find(coding => coding.system === systemTypesUrl)
                            ).map(
                                coding => coding?.system + "|" + coding?.code
                            ).at(0);
                            loaded.deviceType = systemTypeString ?? "undefined";

                            const deviceVersionString = device.version?.filter(
                                version => version.type?.coding?.find(coding => coding.system === "urn:iso:std:iso:11073:10101" && coding.code === "531975")
                            ).map(
                                version => version.value
                            ).at(0);
                            loaded.deviceVersion = deviceVersionString ?? "";

                            const standardVersionElement = device.version?.filter(
                                version => version.type?.coding?.find(coding => coding.system === pandoraStandardsCodeSystemUrl)
                            ).at(0);

                            const standardString = standardVersionElement?.type?.coding?.filter(
                                coding => coding.system === pandoraStandardsCodeSystemUrl
                            ).map(
                                coding => coding?.system + "|" + coding?.code
                            ).at(0);

                            loaded.standard = standardString ?? "undefined";
                            const standardCode = getCode(loaded.standards, loaded.standard);
                            if (standardCode) {
                                loaded.versionsUrl = standardCode.property?.valueString ?? "";
                                try {
                                    loaded.standardsVersions = await valueSetLoader.searchValueSet(standardCode.property?.valueString ?? "");
                                } catch (error) {
                                    console.log(error);
                                    onError();
                                }
                            }

                            const standardVersionString = standardVersionElement?.value;
                            const standardVersionCode = getCode(loaded.standardsVersions, standardVersionString)
                            loaded.standardVersion = standardVersionCode
                                ? standardVersionCode.system + '|' + standardVersionCode.code
                                : "undefined";
                            console.log("loaded : " + loaded.standardVersion)
                        } else if ('Endpoint' === entry.resource?.resourceType) {
                            const endpoint = entry.resource as Endpoint;
                            loaded.endpointId = endpoint.id ?? "";

                            loaded.endpointName = endpoint.name ?? "";
                            loaded.endpointStatus = endpoint.status ? "http://hl7.org/fhir/endpoint-status|" + endpoint.status : "undefined";

                            const endpointConnectionTypeString = endpoint.connectionType?.filter(
                                connectionType => connectionType?.coding?.find(coding => coding.system === endpointConnectionTypeCodeSystemUrl)
                            ).map(
                                connectionType => connectionType?.coding?.find(coding => coding.system === endpointConnectionTypeCodeSystemUrl)
                            ).map(
                                coding => coding?.system + "|" + coding?.code
                            ).at(0);
                            loaded.endpointConnectionType = endpointConnectionTypeString ?? "undefined";

                            const endpointEnvironmentString = endpoint.environmentType?.filter(
                                environmentType => environmentType?.coding?.find(coding => coding.system === endpointEnvironmentsCodeSystemUrl)
                            ).map(
                                environmentType => environmentType?.coding?.find(coding => coding.system === endpointEnvironmentsCodeSystemUrl)
                            ).map(
                                coding => coding?.system + "|" + coding?.code
                            ).at(0);
                            loaded.endpointEnvironmentType = endpointEnvironmentString ?? "undefined";

                            loaded.endpointUrl = endpoint.address;
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                onError();
            }
        }
    }

    function onReset() {
        setState({
            endpointId: '' as string,
            // Device
            active: true as boolean,
            name: '' as string,
            deviceType: '' as string,
            deviceVersion: '' as string,
            standard: '' as string,
            standardVersion: '' as string,
            // Endpoint
            endpointName: '' as string,
            endpointStatus: '' as string,
            endpointConnectionType: '' as string,
            endpointEnvironmentType: '' as string,
            endpointUrl: '' as string,
            // ValueSet related
            versionsUrl: '' as string,
            deviceTypes: [] as SimpleCode[],
            standards: [] as SimpleCode[],
            standardsVersions: [] as SimpleCode[],
            endpointStatuses: [] as SimpleCode[],
            endpointConnectionTypes: [] as SimpleCode[],
            endpointEnvironmentTypes: [] as SimpleCode[],
        } as State);
    }

    /**
     * Reset the versions select when the standard select is reset.
     */
    useEffect(() => {
        if (state.standard === "undefined") {
            set("standardVersion", "undefined");
        } else {
            const updatedState = state;
            const standardCode = getCode(state.standards, state.standard);
            if (standardCode) {
                updatedState.versionsUrl = standardCode.property?.valueString ?? "";
                try {
                    valueSetLoader.searchValueSet(standardCode.property?.valueString ?? "")
                        .then(result => {
                            updatedState.standardsVersions = result;
                            setState(updatedState);
                            forceUpdate();
                        });
                } catch (error) {
                    console.log(error);
                    onError();
                }
            }
        }
    }, [state.standard]);

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    function getOption(code: SimpleCode) {
        return <option value={code.system + '|' + code.code}>{code.display ?? code.code}</option>;
    }

    return (
        <PandoraPage titleKey={systemId ? "title.systemedition" : "title.systemregistration"} loading={loading} needsLogin={true} >
            <div className={styles.systemRegistration}>
                <Card className={styles.card}>
                    <Card.Header className="flexContainer">
                        <Title level={2} content={'Informations'} />
                        <Form>
                            <Form.Check
                                type="switch"
                                label={i18n.t('label.activatethesystem')}
                                checked={state.active}
                                onChange={e => set("active", !state.active)}
                            />
                        </Form>
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div className="spaceBetweenContainer">
                            <div className="mediumContainer">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={i18n.t('label.name').trimEnd()}
                                        value={state.name}
                                        name="name"
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                                <Form.Select className="select"
                                    value={state.deviceType}
                                    name="deviceType"
                                    onChange={handleChange}
                                >
                                    <option value="undefined">
                                        {i18n.t('placeholder.devicetype')}
                                    </option>
                                    {state.deviceTypes.map(deviceType => getOption(deviceType))}
                                </Form.Select>
                                <Form.Select
                                    value={state.standard}
                                    name="standard"
                                    onChange={handleChange}
                                >
                                    <option
                                        value={"undefined"}
                                    >
                                        {i18n.t('placeholder.standard')}
                                    </option>
                                    {state.standards.map(standard => getOption(standard))}
                                </Form.Select>
                            </div>
                            <div className="mediumContainer">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={i18n.t('general.owner').trimEnd()}
                                        disabled={true}
                                    />
                                    <Button
                                        variant="outline-secondary"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                        />
                                    </Button>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={i18n.t('label.deviceversion').trimEnd()}
                                        value={state.deviceVersion}
                                        name="deviceVersion"
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                                <Form.Select
                                    disabled={state.standard === "undefined"}
                                    value={state.standardVersion}
                                    name="standardVersion"
                                    onChange={handleChange}
                                >
                                    <option
                                        value="undefined"
                                    >
                                        {i18n.t('placeholder.standardversion')}
                                    </option>
                                    {state.standardsVersions.map(standardVersion => getOption(standardVersion))}
                                </Form.Select>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header>
                        <Title level={2} content={'EndPoint'} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder={i18n.t('label.name').trimEnd()}
                                value={state.endpointName}
                                name="endpointName"
                                onChange={handleChange}
                            />
                        </InputGroup>
                        <Form.Select className="select"
                            onChange={handleChange}
                            name="endpointStatus"
                            value={state.endpointStatus}
                        >
                            <option value="undefined">
                                {i18n.t('placeholder.status')}
                            </option>
                            {state.endpointStatuses.map(status => getOption(status))}
                        </Form.Select>
                        <Form.Select className="select"
                            onChange={handleChange}
                            name="endpointConnectionType"
                            value={state.endpointConnectionType}
                        >
                            <option value="undefined">
                                {i18n.t('placeholder.connectiontype')}
                            </option>
                            {state.endpointConnectionTypes.map(type => getOption(type))}
                        </Form.Select>
                        <Form.Select className="select"
                            onChange={handleChange}
                            name="endpointEnvironmentType"
                            value={state.endpointEnvironmentType}
                        >
                            <option value="undefined">
                                {i18n.t('placeholder.environmenttype')}
                            </option>
                            {state.endpointEnvironmentTypes.map(environment => getOption(environment))}
                        </Form.Select>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder='URL'
                                value={state.endpointUrl}
                                name="endpointUrl"
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </Card.Body>
                </Card>

                <Button
                    className="button"
                    variant="danger"
                    onClick={onValidateForm}
                >
                    {i18n.t('button.validate')}
                </Button>
                <Button
                    className="button"
                    variant="secondary"
                    onClick={onReset}
                >
                    {i18n.t('button.reset')}
                </Button>
            </div>
        </PandoraPage>
    );
};
export default SystemRegistration;

