// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_homeimageIcon__Satfa {
  width: 25%;
  height: auto;
  margin-right: 4rem;
}

.Home_mainHomeContainer__rM6xM {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  height: 90%;
}

@media screen and (max-width: 991px) {

  .Home_mainHomeContainer__rM6xM {
    margin-top: 13rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;AACb;;AAEA;;EAEE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".homeimageIcon {\r\n  width: 25%;\r\n  height: auto;\r\n  margin-right: 4rem;\r\n}\r\n\r\n.mainHomeContainer {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  margin-top: 10rem;\r\n  height: 90%;\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n\r\n  .mainHomeContainer {\r\n    margin-top: 13rem;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeimageIcon": `Home_homeimageIcon__Satfa`,
	"mainHomeContainer": `Home_mainHomeContainer__rM6xM`
};
export default ___CSS_LOADER_EXPORT___;
