// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestReportResultAlert_TestReportAlertcontainer__Ou0WF {
    position: relative;
    cursor: pointer;
}

.TestReportResultAlert_alertTitle__o9CmE {
    margin-left: 0.5rem;
}

.TestReportResultAlert_TestReportResultDropdown__wWQAi {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--white);
    border: 0.05rem solid var(--gray);
    box-shadow: 0 0.05rem 0.5rem var(--gray);
    z-index: 1000;
    border-radius: 0.4rem;
}

.TestReportResultAlert_TestReportResultDropdownItems__bXKtI {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.TestReportResultAlert_TestReportResultDropdownItems__bXKtI:hover {
    background-color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/TestReport/TestReportResultAlert/TestReportResultAlert.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,8BAA8B;IAC9B,iCAAiC;IACjC,wCAAwC;IACxC,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".TestReportAlertcontainer {\r\n    position: relative;\r\n    cursor: pointer;\r\n}\r\n\r\n.alertTitle {\r\n    margin-left: 0.5rem;\r\n}\r\n\r\n.TestReportResultDropdown {\r\n    position: absolute;\r\n    top: 100%;\r\n    left: 0;\r\n    background-color: var(--white);\r\n    border: 0.05rem solid var(--gray);\r\n    box-shadow: 0 0.05rem 0.5rem var(--gray);\r\n    z-index: 1000;\r\n    border-radius: 0.4rem;\r\n}\r\n\r\n.TestReportResultDropdownItems {\r\n    padding: 0.5rem 1rem;\r\n    cursor: pointer;\r\n}\r\n\r\n.TestReportResultDropdownItems:hover {\r\n    background-color: var(--white);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestReportAlertcontainer": `TestReportResultAlert_TestReportAlertcontainer__Ou0WF`,
	"alertTitle": `TestReportResultAlert_alertTitle__o9CmE`,
	"TestReportResultDropdown": `TestReportResultAlert_TestReportResultDropdown__wWQAi`,
	"TestReportResultDropdownItems": `TestReportResultAlert_TestReportResultDropdownItems__bXKtI`
};
export default ___CSS_LOADER_EXPORT___;
