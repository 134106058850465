// Font awesome
import { faEye } from "@fortawesome/free-solid-svg-icons";
// Fhir front library
import { SearchableTable } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback } from "react";
// Navigation
import { useNavigate } from "react-router-dom";
// Components
import PandoraPage from '../../components/PandoraPage/PandoraPage';
// FHIR
import { Parameters } from "fhir/r5";

const Exchanges: FunctionComponent = () => {

    //////////////////////////////
    //        Navigation        //
    //////////////////////////////

    const navigate = useNavigate();

    const onDetails = useCallback((id: string) => {
        navigate("/ExchangeDetails/" + id);
    }, [navigate]);

    //////////////////////////////
    //           Error          //
    //////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    //////////////////////////////
    //         Functions        //
    //////////////////////////////

    /**
    * Get Method Response to populate the table.
    * 
    * @param parameters    the parameters to the Bundle resource.
    */
    function getMethod(parameters: Parameters): string {
        const methodParam = parameters.parameter?.find(p => p.name === "method");
        return methodParam?.valueCode
            ? methodParam?.valueCode
            : methodParam?.valueCode ? methodParam?.valueCode : "N/A";
    }

    /**
    * Get Path Response to populate the table.
    * 
    * @param parameters    the parameters to the Bundle resource.
    */
    function getPath(parameters: Parameters): string {
        const pathParam = parameters.parameter?.find(p => p.name === "path");
        return pathParam?.valueUri
            ? pathParam?.valueUri
            : pathParam?.valueUri ? pathParam?.valueUri : "N/A";
    }

    /**
    * Get ProxyChannel Response to populate the table.
    * 
    * @param parameters    the parameters to the Bundle resource.
    */
    function getProxyChannel(parameters: Parameters): string {
        const proxyChannelParam = parameters.parameter?.find(p => p.name === "proxyChannel");
        return proxyChannelParam?.valueUri
            ? proxyChannelParam?.valueUri
            : proxyChannelParam?.valueUri ? proxyChannelParam?.valueUri : "N/A";
    }

    //////////////////////////////
    //          Content         //
    //////////////////////////////

    return (
        <PandoraPage titleKey='Messages' needsLogin={true}>
            <SearchableTable
                searchCriteriaProps={{
                    title: i18n.t('title.searchcriteria'),
                    primaryButtonLabel: i18n.t('button.search'),
                    secondaryButtonLabel: i18n.t('button.reset'),
                    language: i18n.t,
                    searchResultModifier: {
                        _elements: "id,parameter",
                        _sort: "-_lastUpdated",
                    },
                    criteria: [
                        {
                            inputProps: {
                                label: i18n.t('label.method'),
                                type: "text",
                            },
                            searchParamsName: "method",
                        },
                        {
                            inputProps: {
                                label: i18n.t('label.path'),
                                type: "text",
                            },
                            searchParamsName: "path:contains",
                        },
                        {
                            inputProps: {
                                label: i18n.t('label.proxychannel'),
                                type: "text",
                            },
                            searchParamsName: "proxyChannel",
                        },
                    ],
                }}
                paginationTableProps={{
                    columns: [
                        {
                            header: i18n.t('label.method'),
                            dataField: "method",
                            width: "25%",
                        },
                        {
                            header: i18n.t('label.path'),
                            dataField: "path",
                            width: "40%",
                        },
                        {
                            header: i18n.t('label.proxychannel'),
                            dataField: "proxyChannel",
                            width: "20%",
                        },
                    ],
                    action: [
                        {
                            icon: faEye,
                            onClick: onDetails,
                        },
                    ],
                    mapResourceToData: (resource: any) => {
                        return {
                            method: getMethod(resource),
                            path: getPath(resource),
                            proxyChannel: getProxyChannel(resource),
                            id: resource.id,
                        };
                    },
                    searchProps: {
                        serverUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir',
                        resourceType: 'Parameters',
                    },
                    onError: onError,
                }}
            />
        </PandoraPage>
    );
};

export default Exchanges;