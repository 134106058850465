
// FHIR
import Client from 'fhir-kit-client';
import { DocumentReference } from 'fhir/r4';
// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// React Router
import { useNavigate, useParams } from "react-router-dom";
// React Bootstrap
import { Button, Form } from "react-bootstrap";
// Components
import PandoraPage from '../../components/PandoraPage/PandoraPage';
// Fhir
import { Title } from "@fyrstain/fhir-front-library";
// Buffer
import { Buffer } from 'buffer';
// Translation
import i18n from "i18next";

const FixtureEdition: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);

    /////////////////////////////////////
    //      Constants / ValueSet       //
    /////////////////////////////////////

    const { fixtureId } = useParams();
    const [fixtureResource, setFixtureResource] = useState({} as DocumentReference);
    const [fixtureContent, setFixtureContent] = useState("");

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setLoading(true);
        await loadTestPlanInformations();
        setLoading(false);
    }

    const navigate = useNavigate();

    /**
     * Load the TestScript informations to display in fields.
     */
    async function loadTestPlanInformations() {
        try {
            const response = await fhirClient.read({
                resourceType: "DocumentReference",
                id: fixtureId ?? '',
            });
            if (response.resourceType !== 'DocumentReference') {
                throw Error(response.statusText);
            }

            setFixtureResource(response as DocumentReference);
            setFixtureContent(Buffer.from((response as DocumentReference).content
                .map(c => c.attachment.data).find(d => d) ?? '', 'base64').toString());
        } catch (error) {
            console.log(error);
            onError();
        }
        return [];
    }

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    async function onSave() {
        const base64 = Buffer.from(fixtureContent).toString('base64');
        const content = fixtureResource.content.find(c => c);
        if (content) {
            content.attachment.data = base64;
        }
        try {
            const response = await fhirClient.update({
                resourceType: "DocumentReference",
                id: fixtureId ?? '',
                body: fixtureResource
            });
        } catch (error) {
            console.log(error);
            onError();
        }
    }

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    return (
        <PandoraPage titleKey='title.fixtureedition' loading={loading} needsLogin={true} >
            <>
                <Form.Group
                    className="col"
                >
                    <Title level={1} content={fixtureResource.id} />
                    <Form.Control
                        as="textarea"
                        rows={20}
                        name="name"
                        readOnly={false}
                        value={fixtureContent}
                        onChange={(e) => setFixtureContent(e.target.value)}
                        className="form-control"
                    />
                </Form.Group>
                <Button
                    className="button"
                    variant="danger"
                    onClick={onSave}
                >
                    {i18n.t('button.save')}
                </Button>
            </>
        </PandoraPage>
    );
};

export default FixtureEdition;
